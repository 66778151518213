import { EyeIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { CrewMerchantUi, helperSchema } from 'corso-types';
import { FC, FormEventHandler, useCallback, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useBeforeUnload } from 'react-router-dom';
import { z } from 'zod';
import api from '~/api';
import Alert from '~/components/Alert';
import DescriptionList from '~/components/DescriptionList';
import {
  DateInput,
  NumberInput,
  SupportingText,
  TextAreaInput,
  TextInput,
} from '~/components/field';
import MediaUploader from '~/components/field/MediaUploader';
import { customFieldValueTypeFormatter } from '~/components/FormatCustomField';
import MediaGallery from '~/components/MediaGallery';
import Modal from '~/components/Modal';
import RelativeDateTime from '~/components/RelativeDateTime';
import { Action } from '~/components/ui/Action';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useClaimLineItemInspectionSettings } from '~/hooks/useClaimLineItemInspection';
import { useStoreId } from '~/hooks/useStoreId';
import {
  useClaimId,
  useInvalidateClaimReview,
} from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';

type InspectionTemplate = CrewMerchantUi.InspectionTemplate;

// * this might be better put into a shared type, similar to CrewMerchantUi.CustomField
type CustomField =
  CrewMerchantUi.ClaimInspectionSettings['templates'][number]['customFields'][number];
// TODO use/get underlying enum
type CustomFieldValueType = CustomField['valueType'];

const customFieldValueTypeSchema = {
  Text: helperSchema.nonEmptyString,
  Number: z.number(),
  Date: z.string().datetime(),
  Select: helperSchema.nonEmptyString,
} as const satisfies Record<CustomFieldValueType, z.ZodType<unknown>>;

type CustomFieldValueTypeData<T extends CustomFieldValueType> = z.infer<
  (typeof customFieldValueTypeSchema)[T]
>;

const customFieldValueTypeInput = {
  Text: ({ customField, onChange, value, error }) => (
    <TextAreaInput // TODO consider text input vs textarea
      id={`${customField.id}`}
      label={customField.displayName}
      details={customField.description}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      error={error}
    />
  ),
  Number: ({ customField, onChange, value, error }) => {
    const parsed = Number.parseFloat(value);
    return (
      <NumberInput
        id={`${customField.id}`}
        label={customField.displayName}
        details={customField.description}
        value={!Number.isNaN(parsed) ? parsed : null}
        onChange={(e) => onChange(e.target.valueAsNumber)}
        error={error}
      />
    );
  },
  Date: ({ customField, onChange, value, error }) => (
    <DateInput
      id={`${customField.id}`}
      label={customField.displayName}
      value={value}
      details={customField.description}
      onChange={onChange}
      error={error}
    />
  ),
  Select: ({ customField, onChange, value, error }) => (
    <SimpleSelect
      label={customField.displayName}
      options={customField.options.map((option) => ({
        label: option,
        value: option,
      }))}
      details={customField.description}
      value={value}
      onChange={onChange}
      error={error}
    />
  ),
} as const satisfies {
  [V in CustomFieldValueType]: FC<{
    customField: CustomField & { valueType: V };
    value: string;
    onChange: (value: CustomFieldValueTypeData<V>) => void;
    error?: string;
  }>;
};

function InspectionDisplay({
  lineItemId,
  inspection,
}: {
  lineItemId: number;
  inspection: CrewMerchantUi.ClaimLineItemInspection;
}) {
  const [showFullInspection, setShowFullInspection] = useState(false);
  const storeId = useStoreId();
  const claimId = useClaimId();
  const { userFullName } = useMerchantContext();
  const invalidateClaim = useInvalidateClaimReview();

  const { mutate: deleteInspection, isPending } = useMutation({
    mutationFn: () =>
      api
        .store(storeId)
        .claim(`${claimId}`, userFullName)
        .lineItem(`${lineItemId}`)
        .inspection.delete(`${inspection.id}`),
    onSuccess: () => invalidateClaim(),
  });

  return (
    <div className="grid grid-cols-2 gap-2 text-sm">
      <div>
        <p>{inspection.name}</p>
        <p className="text-xs text-corso-gray-500">
          Created <RelativeDateTime dateTime={inspection.createdOn} />
        </p>
      </div>
      <div className="flex items-center gap-2 justify-self-end">
        <Action
          icon={EyeIcon}
          accessibilityLabel={`View ${inspection.name}`}
          onClick={() => setShowFullInspection(true)}
        />
        <Modal
          show={showFullInspection}
          onClose={() => setShowFullInspection(false)}
          title={inspection.name}
        >
          <DescriptionList
            layoutType="list"
            descriptions={[
              {
                term: 'Created',
                details: <RelativeDateTime dateTime={inspection.createdOn} />,
              },
              {
                term: 'Grade',
                details: inspection.storeInspectionGrade?.name,
              },
              ...inspection.customFields.map((customField) => ({
                term: customField.displayName,
                details: customFieldValueTypeFormatter[customField.valueType](
                  customField.value,
                ),
              })),
              { term: 'Comment', details: inspection.comment },
              {
                term: 'Media',
                details: !!inspection.images.length && (
                  <MediaGallery
                    media={inspection.images.map(({ url }) => ({
                      src: url,
                      alt: 'Inspection Media',
                    }))}
                  />
                ),
              },
            ].filter(({ details }) => !!details)} // omit terms with no details
          />
        </Modal>
        <Action
          icon={TrashIcon}
          loading={isPending}
          onClick={() => deleteInspection()}
          accessibilityLabel={`Delete ${inspection.name ?? 'Inspection'}`}
        />
      </div>
    </div>
  );
}

/**
 * The type for the form values is different than what is needed to create the inspection
 * mainly to allow the storeInspectionGrade to be nullable for the select
 */
const createSchema = z.object({
  name: z.string().min(1),
  storeInspectionGrade: z
    .object({
      id: z.number(),
    })
    .optional(),
  comment: z.string().optional(),
  customFields: z
    .array(z.object({ id: z.number(), value: z.string() }))
    .optional(),
  images: z
    .array(
      z.object({
        name: z.string(),
        src: z.string(),
      }),
    )
    .optional(),
});

function InspectionCreate({
  lineItem,
}: {
  lineItem: CrewMerchantUi.ClaimLineItem;
}) {
  const [showForm, setShowForm] = useState(false);
  const { userFullName } = useMerchantContext();
  const [inspectionTemplate, setInspectionTemplate] =
    useState<InspectionTemplate>();

  const storeId = useStoreId();
  const claimId = useClaimId();

  const invalidateClaim = useInvalidateClaimReview();

  const {
    data: settings,
    isPending: isSettingsPending,
    error,
  } = useClaimLineItemInspectionSettings(claimId, lineItem.id);

  const inspectionGrades = settings?.inspectionGrades ?? [];

  const {
    mutateAsync: createInspection,
    isPending: isCreateInspectionPending,
    isError,
  } = useMutation({
    mutationFn: ({
      name,
      storeInspectionGrade,
      comment,
      customFields = [],
      images,
    }: z.infer<typeof createSchema>) => {
      if (!claimId) throw new Error('Missing Claim ID');

      return api
        .store(storeId)
        .claim(`${claimId}`, userFullName)
        .lineItem(`${lineItem.id}`)
        .inspection.create({
          name,
          comment,
          customFields,
          storeInspectionGrade,
          images: images?.map(({ name: filename }) => ({
            filename,
          })),
        });
    },
    onSuccess: () => invalidateClaim(),
  });

  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
  } = useForm<z.infer<typeof createSchema>>({
    resolver: zodResolver(createSchema),
  });

  const resetFormCreate = useCallback(() => {
    setShowForm(false);
    setInspectionTemplate(undefined);
    reset();
  }, [reset]);

  const pickInspectionTemplate = useCallback(
    (template?: InspectionTemplate) => {
      setInspectionTemplate(template);
      // when selecting a template, initialize the custom fields with empty values, so one exists for each
      setValue(
        'customFields',
        template?.customFields.map(({ id }) => ({ id, value: '' })) ?? [],
      );

      // the default template is just named 'Inspection', so that, and any others similarly named will not have a default name
      const defaultName =
        template && template.name !== 'Inspection' ? template.name : '';
      setValue('name', defaultName);
    },
    [setValue],
  );

  const customFieldsFieldArray = useFieldArray({
    control,
    name: 'customFields',
  });

  const onSubmit: FormEventHandler = (event) => {
    handleSubmit(
      async (values) => {
        await createInspection(createSchema.parse(values));
        resetFormCreate();
      },
      (invalid) => {
        console.error('Invalid Form Submission', invalid);
      },
    )(event).catch(console.error);
  };

  // * This  handles the browser's beforeunload event such as a refresh
  const onBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!isDirty) return;

      // cancel the event as stated by the standard
      event.preventDefault();
      /** set `returnValue` to help with compatibility @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes */
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
    [isDirty],
  );

  useBeforeUnload(onBeforeUnload);

  if (isSettingsPending) return null; // TODO consider a better loading state
  if (error)
    return (
      <Alert variant="danger" message="Issue Loading Inspection Settings" />
    );

  if (!showForm) {
    return (
      <div className="self-end">
        <Action
          icon={PlusIcon}
          variant="primary"
          onClick={() => setShowForm(true)}
        >
          Add Inspection
        </Action>
      </div>
    );
  }

  if (!inspectionTemplate && settings.templates.length === 1) {
    // always expected to have at least one, i.e. the default, when inspections are enabled
    pickInspectionTemplate(settings.templates[0]);
  }

  return (
    <Modal
      show // always shown if the conditional renders are met
      title={`Add ${inspectionTemplate?.name ?? 'Inspection'} for ${lineItem.originalStoreOrderLineItem.name ?? 'Claim Line Item'}`}
      onClose={resetFormCreate}
    >
      {!inspectionTemplate ?
        <SimpleSelect
          label="Inspection Template"
          options={settings.templates
            .map((template) => ({
              // ! all inspection templates are expected to have a unique name, if not this will cause issues
              label: template.name,
              value: template.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          onChange={(selected) =>
            pickInspectionTemplate(
              settings.templates.find(({ name }) => name === selected),
            )
          }
        />
      : <form onSubmit={onSubmit} className="flex flex-col gap-4">
          {inspectionTemplate.inspectionInstructions && (
            <Alert
              variant="info"
              title="Instructions"
              message={
                <div className="whitespace-pre-wrap text-left">
                  {inspectionTemplate.inspectionInstructions}
                </div>
              }
            />
          )}
          <TextInput
            id="inspection-name"
            label="Name"
            required // schema validates as optional, but required for all new inspections
            {...register('name')}
            error={errors.name?.message}
          />
          {inspectionTemplate.shouldCollectInspectionGrade && (
            <Controller
              control={control}
              name="storeInspectionGrade"
              rules={{ required: 'Inspection grade is required' }}
              render={({ field: { value, onChange }, fieldState }) => (
                <SimpleSelect
                  label="Grade"
                  placeholder="Select an Inspection Grade"
                  options={inspectionGrades
                    .map((inspectionGrade) => ({
                      label: inspectionGrade.name,
                      value: `${inspectionGrade.id}`,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  onChange={(selected) =>
                    onChange(
                      inspectionGrades.find(
                        ({ id }) => id === Number.parseInt(selected, 10),
                      ),
                    )
                  }
                  value={`${value?.id ?? ''}`}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}
          {inspectionTemplate.customFields.map(
            (inspectionTemplateCustomField, i) => {
              const CustomFieldInput =
                customFieldValueTypeInput[
                  inspectionTemplateCustomField.valueType
                ];

              return (
                <CustomFieldInput
                  key={inspectionTemplateCustomField.id}
                  customField={inspectionTemplateCustomField as never} // TODO evaluate why the never typecast is needed
                  value={customFieldsFieldArray.fields[i]?.value ?? ''}
                  onChange={(value) => {
                    if (!customFieldsFieldArray.fields[i]) {
                      // these should be initialized on template selection so one missing would be an invalid state
                      throw new Error('Unexpected Missing Custom Field');
                    }
                    customFieldsFieldArray.update(i, {
                      id: inspectionTemplateCustomField.id,
                      value: `${value}`,
                    });
                  }}
                  error={errors.customFields?.[i]?.value?.message}
                />
              );
            },
          )}
          {inspectionTemplate.showInspectionComment && (
            <TextAreaInput
              id="inspection-comment"
              label="Comment"
              {...register('comment')}
              error={errors.comment?.message}
            />
          )}
          {inspectionTemplate.showInspectionMediaUpload && (
            <Controller
              control={control}
              name="images"
              render={({ field: { value, onChange } }) => (
                <MediaUploader
                  id="inspection-uploads"
                  label="Media"
                  assets={value}
                  onChange={onChange}
                />
              )}
            />
          )}
          {isError && (
            <SupportingText error>
              There was an error saving the inspection. Please try again.
            </SupportingText>
          )}
          <Action
            variant="primary"
            type="submit"
            loading={isCreateInspectionPending}
          >
            Save Inspection
          </Action>
        </form>
      }
    </Modal>
  );
}

type InspectionProps = {
  claimLineItem: CrewMerchantUi.ClaimLineItem;
};

function Inspection({ claimLineItem }: InspectionProps) {
  return (
    <>
      {claimLineItem.inspections.length > 0 && (
        <ol className="flex flex-col gap-px bg-corso-gray-200">
          {claimLineItem.inspections.map((inspection) => (
            <li
              className="bg-white p-2 first:pt-0 last:pb-0"
              key={inspection.id}
            >
              <InspectionDisplay
                lineItemId={claimLineItem.id}
                inspection={inspection}
              />
            </li>
          ))}
        </ol>
      )}
      <InspectionCreate lineItem={claimLineItem} />
    </>
  );
}

export default Inspection;
