import {
  DefaultError,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import api from '~/api';
import { useStoreId } from '~/hooks/useStoreId';
import { useToast } from '~/hooks/useToast';

const useSearchViewQueryKey = () => {
  const storeId = useStoreId();
  return ['searchViews', storeId] as const;
};

const mutationKey = ['searchViews'] as const;

// would be nice to ensure the views returned are only for the entity type wise
export const useSearchViews = <
  Entity extends CrewMerchantUi.SearchView['kind'],
>(
  entity: Entity,
) => {
  const queryKey = useSearchViewQueryKey();

  return useQuery<
    CrewMerchantUi.SearchView[],
    DefaultError,
    Extract<CrewMerchantUi.SearchView, { kind: Entity }>[],
    typeof queryKey
  >({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).searchViews.listViews(),
    select: (views) =>
      views.filter(
        (view): view is Extract<CrewMerchantUi.SearchView, { kind: Entity }> =>
          view.kind === entity,
      ),
  });
};

// not going to worry about optimistic updates for now for the mutations

export const useCreateSearchView = (
  onSuccess?: (data: CrewMerchantUi.SearchView) => void,
) => {
  const storeId = useStoreId();
  const queryKey = useSearchViewQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationKey,
    mutationFn: api.store(storeId).searchViews.createView,
    onSuccess: (view: CrewMerchantUi.SearchView) =>
      // refetching here because useSearchControlBarState needs the updated views before the new view is dispatched
      queryClient.refetchQueries({ queryKey }).then(() => onSuccess?.(view)),
    onError: () => {
      toast.show(`Failed to create view`);
    },
  });
};

export const useUpdateSearchView = (
  onSuccess?: (data: CrewMerchantUi.SearchView) => void,
) => {
  const storeId = useStoreId();
  const queryKey = useSearchViewQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationKey,
    mutationFn: api.store(storeId).searchViews.updateView,
    onSuccess: (data) => onSuccess?.(data),
    onError: () => {
      toast.show(`Failed to update view`);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });
};

export const useDeleteSearchView = (onSuccess?: () => void) => {
  const storeId = useStoreId();
  const queryKey = useSearchViewQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: api.store(storeId).searchViews.deleteView,
    onSuccess: () => {
      toast.show('View deleted');
      onSuccess?.();
    },
    onError: () => {
      toast.show('Failed to delete view');
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });
};
