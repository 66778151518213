import {
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
  CrewClaimRollupStatusCode,
  crewClaimRollupStatusCodeName,
  CrewMerchantUi,
  ReportColumn,
} from 'corso-types';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { subDays } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import api from '~/api';
import Alert from '~/components/Alert';
import DescriptionList, { Description } from '~/components/DescriptionList';
import Modal from '~/components/Modal';
import { Action } from '~/components/ui/Action';
import { MultiSelect } from '~/components/ui/MultiSelect';
import { useCustomFields } from '~/hooks/useCustomFields';
import { useStoreId } from '~/hooks/useStoreId';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';

export default function RunReport({
  show,
  claimType,
  createdOn,
  resolutionMethods,
  statusCodes,
  orderBy,
  searchTerm,
  onClose,
}: {
  claimType: 'Return' | 'Warranty';
  orderBy: 'asc' | 'desc';
  createdOn?: { start: string | null; end: string | null };
  statusCodes?: 'all' | CrewClaimRollupStatusCode[];
  resolutionMethods?: 'all' | CrewClaimResolutionMethodEnum[];
  searchTerm?: string;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();
  const { user } = useMerchantContext();

  const { data: customFields = [] } = useCustomFields();

  const defaultColumns = Object.values(ReportColumn);

  const customFieldSelectOptions = customFields.map((customField) => ({
    label: customField.displayName,
    value: customField.id,
  }));

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm<CrewMerchantUi.ReportClaimReqFormValues>({
    values: {
      columns: defaultColumns,
      inspectionCustomFieldIds: [],
      lineItemCustomFieldIds: [],
    },

    resolver: zodResolver(CrewMerchantUi.reportRequestFormValueSchema),
  });

  const { mutate: runReport, isPending } = useMutation({
    mutationFn: api.store(storeId).report,
  });

  const closeAndReset = () => {
    onClose();
    reset();
  };

  const columnOptions = Object.values(ReportColumn).map((value) => ({
    label: value,
    value,
  }));

  const descriptions: Description[] = [];

  if (createdOn?.start) {
    descriptions.push({
      term: 'Start Date',
      details: formatter.date(createdOn.start),
    });
  } else {
    // ? if no start date, default to 30 days ago
    descriptions.push({
      term: 'Start Date',
      details: formatter.date(subDays(new Date(), 30)),
    });
  }

  if (createdOn?.end) {
    descriptions.push({
      term: 'End Date',
      details: formatter.date(createdOn.end),
    });
  }

  if (statusCodes && statusCodes.length) {
    descriptions.push({
      term: 'Status',
      details:
        statusCodes === 'all' ? 'All' : (
          statusCodes
            .map((statusCode) => crewClaimRollupStatusCodeName[statusCode])
            .join(', ')
        ),
    });
  }

  if (resolutionMethods && resolutionMethods.length) {
    descriptions.push({
      term: 'Resolution',
      details:
        resolutionMethods === 'all' ? 'All' : (
          resolutionMethods
            .map((method) => crewClaimResolutionMethodEnumName[method])
            .join(', ')
        ),
    });
  }

  if (searchTerm?.length) {
    descriptions.push({
      term: 'Search Term',
      details: searchTerm,
    });
  }

  const formId = 'generate-report-form';
  return (
    <Modal
      title="Export Claims"
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Action onClick={closeAndReset}>Cancel</Action>
          <Action
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
            loading={isPending}
          >
            Export
          </Action>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((body) => {
          onClose();
          runReport({
            email: user.email,
            cursor: null,
            take: 100,
            kind: claimType,
            orderBy,
            filters: {
              searchTerm,
              createdOn,
              statusCodes:
                !statusCodes || !statusCodes.length || statusCodes === 'all' ?
                  undefined
                : statusCodes,
              requestedResolutionMethods:
                (
                  !resolutionMethods ||
                  !resolutionMethods.length ||
                  resolutionMethods === 'all'
                ) ?
                  undefined
                : resolutionMethods,
            },
            ...body,
          });
        })}
        className="flex flex-col gap-4"
      >
        <Alert
          message={
            <p>
              Your export will be emailed to:{' '}
              <span className="font-bold">{user.email}.</span>
            </p>
          }
        />
        {!!descriptions.length && (
          <>
            <span className="text-sm font-medium"> Filter Details</span>
            <DescriptionList descriptions={descriptions} />
          </>
        )}

        <Controller
          name="columns"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiSelect
              togglable
              label="Columns"
              options={columnOptions}
              value={value.map((option) => ({ label: option, value: option }))}
              placeholder="Select columns"
              onChange={(selected) => onChange(selected.map((v) => v.label))}
              error={errors.columns?.message}
            />
          )}
        />

        <Controller
          name="lineItemCustomFieldIds"
          control={control}
          render={({ field, fieldState }) => (
            <MultiSelect
              togglable
              details="Custom Field responses collected in the Customer Portal."
              label="Custom Field Responses - Customer Provided"
              options={customFieldSelectOptions.map(({ label, value }) => ({
                label,
                value: `${value}`,
              }))}
              value={field.value.map((value) => ({
                label: customFieldSelectOptions.find((cf) => cf.value === value)
                  ?.label,
                value: `${value}`,
              }))}
              onChange={(selections) =>
                field.onChange(
                  selections.map(({ value }) => Number.parseInt(value, 10)),
                )
              }
              placeholder="Select custom fields"
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="inspectionCustomFieldIds"
          control={control}
          render={({ field, fieldState }) => (
            <MultiSelect
              togglable
              label="Custom Field Responses - Inspection"
              placeholder="Select custom fields"
              options={customFieldSelectOptions.map(({ label, value }) => ({
                label,
                value: `${value}`,
              }))}
              value={field.value.map((value) => ({
                label: customFieldSelectOptions.find((cf) => cf.value === value)
                  ?.label,
                value: `${value}`,
              }))}
              onChange={(selections) =>
                field.onChange(
                  selections.map(({ value }) => Number.parseInt(value, 10)),
                )
              }
              error={fieldState.error?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
}
