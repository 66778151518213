import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Card from '~/components/Card';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import {
  EmailInput,
  NumberInput,
  SwitchInput,
  TextAreaInput,
  TextInput,
} from '~/components/field';
import FloatingSave from '~/components/FloatingSave';
import Page from '~/components/Page';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
} from '~/hooks/useConfigSettings';
import { EmailSettingsFormValues, emailSettingsFromSchema } from '~/types';

function AutomatedEmailTemplate({
  id,
  index,
  name,
  emailType,
}: {
  id: number;
  index: number;
  name: string;
  emailType:
    | 'Return_Expiring'
    | 'Return_Reminder'
    | 'Claim_Submitted'
    | 'Warranty_Submitted'
    | 'Registration_Submitted';
}) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<EmailSettingsFormValues>();

  const showSendAfterDays =
    emailType === 'Return_Expiring' || emailType === 'Return_Reminder';

  return (
    <Disclosure
      renderSummary={
        <SimpleSummary>
          {' '}
          <div className="flex w-full items-center justify-between">
            <span>{name}</span>

            <Controller
              control={control}
              name={`storeEmailTemplates.automatedEmailTemplates.${index}.isEnabled`}
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id={`automated-email-template-${id}-enabled`}
                  label={name}
                  labelVisuallyHidden
                  checked={value}
                  onChange={onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </div>
        </SimpleSummary>
      }
    >
      <fieldset className="flex flex-col gap-3 border-b border-corso-gray-200 pb-4 last:border-b-0 last:pb-0">
        <div className="flex flex-col gap-3 pl-4 md:pl-5">
          {showSendAfterDays && (
            <NumberInput
              addon={{
                insideEnd: 'days',
              }}
              min={1}
              id={`automated-email-template-${id}-send-after-days`}
              label="Send Delay"
              details="Number of days to wait before sending this email, after the claim is created."
              required
              {...register(
                `storeEmailTemplates.automatedEmailTemplates.${index}.sendAfterDays`,
                { valueAsNumber: true },
              )}
              error={
                errors.storeEmailTemplates?.automatedEmailTemplates?.[index]
                  ?.sendAfterDays?.message
              }
            />
          )}

          <TextInput
            id={`automated-email-template-${id}-subject`}
            label="Subject"
            required
            {...register(
              `storeEmailTemplates.automatedEmailTemplates.${index}.subject`,
            )}
            error={
              errors.storeEmailTemplates?.automatedEmailTemplates?.[index]
                ?.subject?.message
            }
          />

          <TextInput
            id={`automated-email-template-${id}-header`}
            label="Header Text"
            required
            {...register(
              `storeEmailTemplates.automatedEmailTemplates.${index}.header`,
            )}
            error={
              errors.storeEmailTemplates?.automatedEmailTemplates?.[index]
                ?.header?.message
            }
          />
          <TextAreaInput
            id={`automated-email-template-${id}-body`}
            label="Body Text"
            required
            {...register(
              `storeEmailTemplates.automatedEmailTemplates.${index}.body`,
            )}
            error={
              errors.storeEmailTemplates?.automatedEmailTemplates?.[index]?.body
                ?.message
            }
          />
        </div>
      </fieldset>
    </Disclosure>
  );
}

function FinalizeEmailTemplate({
  id,
  index,
  name,
}: {
  id: number;
  index: number;
  name: string;
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext<EmailSettingsFormValues>();

  return (
    <Disclosure
      renderSummary={
        <SimpleSummary>
          {' '}
          <div className="flex w-full items-center justify-between">
            <span>{name}</span>
          </div>
        </SimpleSummary>
      }
    >
      <fieldset className="ml-4 mt-4 flex flex-col gap-2 first:mt-0">
        <TextInput
          id={`automated-email-template-${id}-subject`}
          label="Subject"
          required
          {...register(
            `storeEmailTemplates.finalizeEmailTemplates.${index}.subject`,
          )}
          error={
            errors.storeEmailTemplates?.finalizeEmailTemplates?.[index]?.subject
              ?.message
          }
        />
        <TextInput
          id={`automated-email-template-${id}-header`}
          label="Header Text"
          required
          {...register(
            `storeEmailTemplates.finalizeEmailTemplates.${index}.header`,
          )}
          error={
            errors.storeEmailTemplates?.finalizeEmailTemplates?.[index]?.header
              ?.message
          }
        />
        <TextAreaInput
          id={`automated-email-template-${id}-body`}
          label="Body Text"
          required
          {...register(
            `storeEmailTemplates.finalizeEmailTemplates.${index}.body`,
          )}
          error={
            errors.storeEmailTemplates?.finalizeEmailTemplates?.[index]?.body
              ?.message
          }
        />
      </fieldset>
    </Disclosure>
  );
}

const formId = 'email-settings';

export default function EmailSettings() {
  const { data } = useConfigSettings(({ email }) => email);
  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();
  const methods = useForm<EmailSettingsFormValues>({
    resolver: zodResolver(emailSettingsFromSchema),
    values: data,
  });

  const { verifiedEmailDomain } = data ?? {};

  // * using useFieldArray to get access to the fields array won't cause an error if templates is undefined vs getValues from useSettingsForm
  const { fields: automatedEmailTemplates } = useFieldArray({
    control: methods.control,
    name: 'storeEmailTemplates.automatedEmailTemplates',
    //* react-hook-form is overriding the id with a generated id and causing some issues, so assigning it a different key
    keyName: '_id',
  });
  const { fields: finalizeEmailTemplates } = useFieldArray({
    control: methods.control,
    name: 'storeEmailTemplates.finalizeEmailTemplates',
    //* react-hook-form is overriding the id with a generated id and causing some issues, so assigning it a different key
    keyName: '_id',
  });
  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit((updates) => saveChanges({ email: updates }))(event)
      .catch(console.error);
  };
  return (
    <Page title="Email">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form
          id={formId}
          data-testid={formId}
          className="flex flex-col gap-4"
          onSubmit={submitHandler}
          onReset={(e) => {
            e.preventDefault();
            methods.reset();
          }}
        >
          <FloatingSave
            isDirty={methods.formState.isDirty}
            isSubmitting={methods.formState.isSubmitting}
            form={formId}
          />
          <Card>
            <Card.Heading as="h2">Email Recipient and Sender</Card.Heading>
            <TextInput
              id="email-from-name"
              label="Send from Name"
              placeholder="John Doe"
              details="The name that emails will be sent from."
              required
              {...methods.register('emailSendFromName')}
              error={methods.formState.errors.emailSendFromName?.message}
            />

            <EmailInput
              id="email-from-address"
              label="Send from Email"
              placeholder="john.doe@example.com"
              details={[
                'The email address that emails will be sent from.',
                !verifiedEmailDomain ?
                  'Reach out to us if you would like to send from a custom domain.'
                : '',
              ]
                .join(' ')
                .trim()}
              required
              readOnly={!verifiedEmailDomain}
              disabled={!verifiedEmailDomain}
              {...methods.register('emailSendFrom')}
              error={methods.formState.errors.emailSendFrom?.message}
            />
            <TextInput
              id="email-reply-to-name"
              label="Reply to Name"
              placeholder="John Doe"
              details="The name that emails reply to."
              required
              {...methods.register('emailReplyToName')}
              error={methods.formState.errors.emailReplyToName?.message}
            />
            <EmailInput
              id="email-reply-to-address"
              label="Reply to Email"
              placeholder="john.doe@example.com"
              details="The email address that emails will reply to."
              required
              {...methods.register('emailReplyTo')}
              error={methods.formState.errors.emailReplyTo?.message}
            />
          </Card>
          <Card>
            <Card.Heading as="h2">Automated Emails</Card.Heading>
            {automatedEmailTemplates.map(({ id, name, type }, i) => (
              <AutomatedEmailTemplate
                key={id}
                id={id}
                index={i}
                name={name}
                emailType={type}
              />
            ))}
          </Card>
          <Card>
            <Card.Heading as="h2">Claim Finalization Emails</Card.Heading>
            {finalizeEmailTemplates.map(({ id, name }, i) => (
              <FinalizeEmailTemplate key={id} id={id} index={i} name={name} />
            ))}
          </Card>
        </form>
      </FormProvider>
    </Page>
  );
}
