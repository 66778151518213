import { VariantProps } from 'class-variance-authority';
import {
  ButtonHTMLAttributes,
  ComponentProps,
  forwardRef,
  ReactNode,
} from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Icon, { iconVariants, SupportedIcon } from '~/components/Icon';
import SpinnerIcon from '~/components/SpinnerIcon';
import { cn } from '~/utils/shadcn';
import { Button, buttonVariants } from './primitives/Button';

type ActionContent =
  | { icon: SupportedIcon; accessibilityLabel: string; children?: ReactNode }
  | {
      icon?: SupportedIcon;
      accessibilityLabel?: string;
      children: NonNullable<ReactNode>;
    };

// TODO consider implementing variants for icon size
// TODO consider showing the `accessibilityLabel` as a tooltip on hover
function ActionContent({
  icon,
  accessibilityLabel,
  children,
  loading = false,
  side = 'left',
  size,
}: Partial<ActionContent> & {
  loading?: boolean;
  side?: 'left' | 'right';
  size?: VariantProps<typeof iconVariants>['size'];
}) {
  return (
    <span
      className={cn(
        'inline-flex w-full items-center justify-center gap-2',
        side === 'left' ? 'flex-row' : 'flex-row-reverse',
      )}
    >
      {loading ?
        <SpinnerIcon size={size} />
      : icon !== undefined && <Icon icon={icon} size={size} />}
      {accessibilityLabel && (
        <span className="sr-only">{accessibilityLabel}</span>
      )}
      {children}
    </span>
  );
}

type LinkAction = Pick<LinkProps, 'to' | 'target' | 'download'>;
// support all button props except `className`, since that's the forwarded ref
type ButtonAction = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'className' | 'children' | 'title'
>;

/**
 * From the `BaseAction`, an `Action` may have an `icon`, and if it does, it must have an `accessibilityLabel` or `content`.
 * With an `icon` and no `content`, the action will be a button with just the `icon`, but it it will have the `accessibilityLabel` as an `aria-label`.
 * With `content` and no `icon`, the action will be a button with just the `content`.
 * With both `icon` and `content`, the action will be a button with the `icon` and `content`, and optionally if the the `accessibilityLabel` is provided, it will be used as the `aria-label`.
 */
export type Action = ActionContent &
  (({ to: LinkProps['to'] } & LinkAction) | ({ to?: never } & ButtonAction));

export type ActionProps = Action & {
  /** Provided for utility, but should be used sparingly. */
  className?: string;
  loading?: boolean;
  side?: ComponentProps<typeof ActionContent>['side'];
  iconSize?: ComponentProps<typeof ActionContent>['size'];
} & Omit<VariantProps<typeof buttonVariants>, 'icon'>;

/**
 * A smart component wrapping the `ui/primitive/Button`, to provide additional functionality.
 * Mostly being able to disambiguate links and callbacks.
 * Additionally supports:
 * - A `loading` state.
 * - Consistent display of icons to the `side` of the content on the left or right; defaulting to the `left`.
 *
 * Defaults to a button action, but will render as a link when appropriate.
 */
const Action = forwardRef<HTMLButtonElement, ActionProps>(
  (
    {
      className,
      loading = false,

      // button variants
      pressed,
      variant,
      size,

      // action content
      accessibilityLabel,
      children,
      icon,
      side,
      iconSize,

      // remaining action
      ...action
    },
    ref,
  ) => {
    if ('to' in action && action.to !== undefined) {
      return (
        <Button
          className={className}
          ref={ref}
          size={size}
          icon={icon && (children === undefined || children === null)}
          disabled={loading}
          variant={variant}
          pressed={pressed}
          asChild
        >
          <Link
            className={cn(loading && 'pointer-events-none cursor-wait')}
            // * props spreading to pass through the link action props
            {...action}
          >
            <ActionContent
              icon={icon}
              accessibilityLabel={accessibilityLabel}
              loading={loading}
              side={side}
              size={iconSize}
            >
              {children}
            </ActionContent>
          </Link>
        </Button>
      );
    }

    return (
      <Button
        className={cn(
          loading && 'cursor-wait',
          action.disabled && 'cursor-not-allowed',
          className,
        )}
        ref={ref}
        size={size}
        icon={icon && (children === undefined || children === null)}
        disabled={loading || action.disabled}
        variant={variant}
        pressed={pressed}
        // * props spreading necessary in conjunction with forwarding the ref in order to work well with `asChild` triggers, and passing through the button action props
        {...action}
      >
        <ActionContent
          icon={icon}
          accessibilityLabel={accessibilityLabel}
          loading={loading}
          side={side}
          size={iconSize}
        >
          {children}
        </ActionContent>
      </Button>
    );
  },
);

Action.displayName = 'Action';

export { Action };
