import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons';

import { CrewMerchantUi } from 'corso-types';

import Card from '~/components/Card';
import { Action } from '~/components/ui/Action';
import SimpleTooltip from '~/components/ui/SimpleTooltip';

type Shipment = NonNullable<CrewMerchantUi.ClaimShipment>;

export default function ShipmentPackingSlip({
  shipment,
}: {
  shipment: Shipment;
}) {
  return (
    <div className="flex flex-col gap-2">
      <Card.Heading> Packing Slip </Card.Heading>
      {shipment.returnShipmentPdfAssetUrl && (
        <SimpleTooltip content="Download Packing Slip">
          <Action
            icon={faFileArrowDown}
            to={shipment.returnShipmentPdfAssetUrl}
            accessibilityLabel="Download"
            download
          />
        </SimpleTooltip>
      )}
    </div>
  );
}
