import { faFeather, faPen, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler, ReactNode, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import DescriptionList, { Description } from '~/components/DescriptionList';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import EmptyStateAction from '~/components/EmptyStateAction';
import { TextInput } from '~/components/field';
import Modal from '~/components/Modal';
import Page from '~/components/Page';
import Skeleton from '~/components/Skeleton';
import { Action } from '~/components/ui/Action';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useCustomFields,
  useCustomFieldsDelete,
  useCustomFieldsUpsert,
} from '~/hooks/useCustomFields';
import {
  CustomField,
  customFieldCreateSchema,
  customFieldUpdateSchema,
  UpsertFormProps,
} from '~/types';

function CustomFieldForm({
  show,
  onClose,
  onSubmit,
  values,
}: UpsertFormProps<CustomField>) {
  const update = !!values?.id;

  const schemaToUse =
    update ? customFieldUpdateSchema : customFieldCreateSchema;

  // eslint-disable-next-line no-underscore-dangle
  const valueTypes = customFieldCreateSchema.shape.valueType._def.options.map(
    (option) => option.value,
  );

  const valueTypeOptions = valueTypes.map((valueType) => ({
    value: valueType,
    displayName: valueType,
  }));

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
    register,
    formState,
  } = useForm({
    resolver: zodResolver(schemaToUse),
    values,
  });

  const selectedValueType = watch('valueType');

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((formValues) => {
      onSubmit(formValues);
      reset();
    })(event).catch(console.error);
  };

  return (
    <Modal
      title="Custom Fields"
      description="Custom fields allow you to collect and store additional information about a claim or registration."
      show={show}
      onClose={onClose}
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <TextInput
          id="display-name"
          label="Name"
          details="This name will be used as a label on the form field in both the Customer App and Merchant Admin. (eg. 'Serial Number')"
          required
          {...register('displayName')}
          error={errors.displayName?.message}
        />

        <TextInput
          id="name"
          label="Description"
          details="This description will be used as a hint when filling out the field in both the Customer App and Merchant Admin. (eg. 'The Serial Number can be found on the back of the device')"
          required
          {...register('description')}
          error={errors.description?.message}
        />

        {!update && (
          <Controller
            name="valueType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SimpleSelect
                label="Value Type"
                options={valueTypeOptions.map((valueTypeOption) => ({
                  label: valueTypeOption.displayName,
                  value: valueTypeOption.value,
                }))}
                value={value}
                onChange={onChange}
                details="The type of value that will be stored in this field."
                error={formState.errors.valueType?.message}
              />
            )}
          />
        )}

        {selectedValueType === 'Select' && (
          <TextInput
            id="value-type-options"
            label="Options"
            details="A comma-separated list of options that the user can select from. (eg. 'Red, Blue, Green')"
            {...register('options')}
            error={errors.options?.message}
            required // required when valueType is 'Select'
          />
        )}

        <Action variant="primary" type="submit">
          {update ? 'Save' : 'Create'} Custom Field
        </Action>
      </form>
    </Modal>
  );
}

function CustomFieldsSkeleton() {
  return (
    <div className="flex gap-2">
      <Skeleton.Rectangle height="36px" width="100%" />
      <Skeleton.Rectangle height="36px" width="44px" />
      <Skeleton.Rectangle height="36px" width="44px" />
    </div>
  );
}

function DeleteCustomField({ cf }: { cf: CustomField }) {
  const { mutate: deleteCustomField } = useCustomFieldsDelete();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmDelete = () => {
    deleteCustomField(cf);
    setShowConfirmation(false);
  };

  return (
    <>
      <Action
        icon={faTrashCan}
        accessibilityLabel={`Delete ${cf.displayName}`}
        onClick={() => setShowConfirmation(true)}
      />

      <ConfirmModal
        title="Delete Custom Field"
        prompt={`Are you sure you want to delete ${cf.displayName}?`}
        confirmText="Delete"
        cancelText="Cancel"
        show={showConfirmation}
        onConfirm={confirmDelete}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
}

function EditCustomField({ cf }: { cf: CustomField }) {
  const { mutate: saveCustomField } = useCustomFieldsUpsert();
  const [showForm, setShowForm] = useState(false);

  const saveEdits = (edits: CustomField) => {
    saveCustomField(edits);
    setShowForm(false);
  };

  return (
    <>
      <Action
        icon={faPen}
        accessibilityLabel={`Edit ${cf.displayName} group`}
        onClick={() => setShowForm(true)}
      />

      <CustomFieldForm
        show={showForm}
        onClose={() => setShowForm(false)}
        onSubmit={saveEdits}
        values={cf}
      />
    </>
  );
}

function CustomFieldDisplay({
  customField: { displayName, valueType, description, options },
  actions,
}: {
  customField: CustomField;
  actions?: ReactNode;
}) {
  const descriptions: Description[] = [];

  descriptions.push({
    term: 'Description',
    details: description,
  });

  if (options) {
    descriptions.push({
      term: 'Options',
      details: options,
    });
  }

  descriptions.push({ term: 'Value Type', details: valueType });

  return (
    <Disclosure
      renderSummary={
        <SimpleSummary>
          <div className="flex w-full flex-row items-center justify-between gap-2 pl-2">
            <h4 className="text-sm font-medium text-corso-gray-800">
              {displayName}
            </h4>
            {actions}
          </div>
        </SimpleSummary>
      }
    >
      <div className="mt-1 pl-4">
        <DescriptionList descriptions={descriptions} />
      </div>
    </Disclosure>
  );
}

export default function CustomFieldSettings() {
  const { data: customFields = null, isLoading } = useCustomFields();
  const [showForm, setShowForm] = useState(false);
  const { mutate: saveCustomField } = useCustomFieldsUpsert();

  return (
    <Page
      title="Custom Fields"
      primaryAction={{
        onAction: () => setShowForm(true),
        icon: PlusIcon,
        content: 'Add Custom Field',
      }}
    >
      <Card>
        <Skeleton
          instances={1}
          skeleton={CustomFieldsSkeleton}
          isLoading={isLoading}
        >
          {customFields?.length === 0 && (
            <EmptyStateAction.Button
              onClick={() => setShowForm(true)}
              icon={<FontAwesomeIcon icon={faFeather} />}
              label="Create a custom field"
              description="Custom fields allow you to allow you to collect and store additional information about a claim or registration."
            />
          )}
          <ul className="flex flex-col gap-2">
            {customFields?.map((cf) => (
              <li key={cf.id}>
                <CustomFieldDisplay
                  customField={cf}
                  actions={
                    <div className="flex flex-row gap-2">
                      <EditCustomField cf={cf} />
                      <DeleteCustomField cf={cf} />
                    </div>
                  }
                />
              </li>
            ))}
          </ul>
          <CustomFieldForm
            show={showForm}
            onClose={() => setShowForm(false)}
            onSubmit={(customField) => {
              saveCustomField(customField);
              setShowForm(false);
            }}
          />
        </Skeleton>
      </Card>
    </Page>
  );
}
