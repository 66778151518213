import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { JSX, ReactNode } from 'react';
import Modal from './Modal';
import { Action } from './ui/Action';

// ? possibly abstract a generic, reusable modal component

type ConfirmVariant = 'destructive' | 'caution' | 'primary';

const variantIcon = {
  destructive: (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-corso-red-50 sm:mx-0 sm:h-10 sm:w-10">
      <ExclamationTriangleIcon
        className="h-6 w-6 text-corso-red-600"
        aria-hidden="true"
      />
    </div>
  ),

  primary: null,
  caution: (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-amber-100 sm:mx-0 sm:h-10 sm:w-10">
      <ExclamationCircleIcon
        className="h-6 w-6 text-amber-600"
        aria-hidden="true"
      />
    </div>
  ),
} satisfies Record<ConfirmVariant, JSX.Element | null>;

type ConfirmModalProps = {
  title: string;
  prompt: ReactNode;
  show: boolean;
  onCancel: () => void;
  cancelText?: string;

  onConfirm: () => void;
  confirmText?: string;
  loading?: boolean;

  variant?: ConfirmVariant;
};

export default function ConfirmModal({
  title,
  prompt,
  show,
  onCancel,
  cancelText = 'Cancel',
  onConfirm,
  confirmText = 'OK',
  variant = 'destructive',
  loading = false,
}: ConfirmModalProps) {
  return (
    <Modal
      show={show}
      onClose={onCancel}
      title={title}
      icon={variantIcon[variant]}
      actions={
        <>
          <Action onClick={onCancel}>{cancelText}</Action>
          <Action variant={variant} onClick={onConfirm} loading={loading}>
            {confirmText}
          </Action>
        </>
      }
    >
      <p className="text-sm text-corso-gray-500">{prompt}</p>
    </Modal>
  );
}
