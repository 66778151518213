import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import api from '~/api';
import { useClaimReviewContext } from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { useStoreId } from './useStoreId';

/**
 * A prefix for all tag-based queries for the purposes of easy invalidation.
 * @see https://tanstack.com/query/latest/docs/framework/react/guides/query-invalidation
 */
const queryKeyPrefix = 'claimTags';

// * store claim tags
export function useStoreClaimTagsCreate() {
  const storeId = useStoreId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (name: string) =>
      api.store(storeId).storeClaimTags.create({ name }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeyPrefix, storeId] }),
  });
}

/** Available tags across the store. */
export function useStoreClaimTags<Data = CrewMerchantUi.ClaimTag[]>(
  select?: (claimTags: { id: number; name: string }[]) => Data,
) {
  const storeId = useStoreId();
  return useQuery({
    queryKey: [queryKeyPrefix, storeId],
    queryFn: () => api.store(storeId).storeClaimTags.list(),
    select,
  });
}

/** Delete a tag from the store. */
export function useStoreClaimTagDelete() {
  const storeId = useStoreId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tagId: number) =>
      api.store(storeId).storeClaimTags.delete(`${tagId}`),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeyPrefix, storeId] }), // invalidate any queries with the query key prefix for the store
  });
}

// * claim tags // * only available within the context of a claim

/** Query key for the tags associated with a specific claim. */
function useClaimTagsQueryKey() {
  const storeId = useStoreId();
  const { claimReview } = useClaimReviewContext();
  const claimId = claimReview.watch('claim.id');
  const { userFullName } = useMerchantContext();

  return [queryKeyPrefix, storeId, `${claimId}`, userFullName] as const;
}

/** Current tags for the claim. */
export function useClaimTags() {
  const claimTagsQueryKey = useClaimTagsQueryKey();
  return useQuery({
    queryKey: claimTagsQueryKey,
    queryFn: ({ queryKey: [, storeId, claimId, loggedInUser] }) =>
      api.store(storeId).claim(claimId, loggedInUser).tags.list(),
  });
}

/** Upsert tags for a specific claim. */
export function useClaimTagsUpsert() {
  const queryClient = useQueryClient();
  const claimTagsQueryKey = useClaimTagsQueryKey();

  const [, storeId, claimId, loggedInUser] = claimTagsQueryKey;

  return useMutation({
    mutationFn: (tags: string[]) =>
      api
        .store(storeId)
        .claim(claimId, loggedInUser)
        .tags.upsert(tags.map((name) => ({ name }))),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeyPrefix, storeId] }), // invalidate any queries with the query key prefix for the store
  });
}
