import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/utils/shadcn';

// TODO consider adding a loading variant for all buttons
const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap text-wrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'border border-neutral-200 bg-white text-neutral-700 shadow-sm hover:bg-neutral-200/80 hover:text-neutral-900 active:bg-neutral-200',
        ghost:
          'text-neutral-700 hover:bg-neutral-200/80 hover:text-neutral-900 active:bg-neutral-200',
        primary:
          'border border-corso-blue-700 bg-corso-blue-600 text-white shadow-sm hover:bg-corso-blue-600/90 active:bg-corso-blue-700',
        destructive:
          'border border-red-700 bg-red-600 text-white shadow-sm hover:bg-red-600/90 active:bg-red-700',
        caution:
          'border border-amber-700 bg-amber-600 text-white shadow-sm hover:bg-amber-600/90 active:bg-amber-700',
        link: 'text-corso-blue-800 underline-offset-2 hover:text-corso-blue-600 hover:underline active:text-corso-blue-700',
      },
      size: {
        // twice the horizontal padding as vertical padding
        default: 'px-4 py-2',
        lg: 'px-6 py-3',
      },
      icon: { true: null }, // providing a variant to be used as part of a compound variant
      pressed: { true: null }, // providing a variant to be used as part of a compound variant // ? maybe relevant styles just becomes a selector
    },
    compoundVariants: [
      // TODO way to verify all sizes are overridden
      // icon sizes; scaled to match text roughly based on default `Icon` size
      {
        icon: true,
        size: 'default',
        className: 'p-2',
      },
      {
        icon: true,
        size: 'lg',
        className: 'p-3',
      },

      // TODO find a way to disallow `size` when `variant` is `link`
      // link override to remove whitespace
      {
        variant: 'link',
        size: ['default', 'lg'],
        className: 'p-0',
      },

      // pressed variants
      {
        variant: 'default',
        pressed: true,
        className:
          'aria-pressed:bg-neutral-200 aria-pressed:hover:bg-neutral-200',
      },
      {
        variant: 'ghost',
        pressed: true,
        className:
          'aria-pressed:bg-neutral-200 aria-pressed:hover:bg-neutral-200',
      },
      {
        variant: 'primary',
        pressed: true,
        className:
          'aria-pressed:bg-corso-blue-700 aria-pressed:hover:bg-corso-blue-700',
      },
      {
        variant: 'destructive',
        pressed: true,
        className: 'aria-pressed:bg-red-700 aria-pressed:hover:bg-red-700',
      },
      {
        variant: 'caution',
        pressed: true,
        className: 'aria-pressed:bg-amber-700 aria-pressed:hover:bg-amber-700',
      },
      {
        variant: 'link',
        pressed: true,
        className:
          'aria-pressed:text-corso-blue-700 aria-pressed:hover:text-corso-blue-700',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
      icon: false,
      pressed: false,
    },
  },
);

export type ButtonProps = {
  asChild?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      icon,
      pressed,
      asChild = false,
      // default to `button` type, since the normal default is `submit`
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        aria-pressed={pressed ?? undefined}
        className={cn(
          buttonVariants({ variant, size, icon, pressed, className }),
        )}
        ref={ref}
        type={type}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
