import {
  ClaimResolutionMethodEnum,
  CrewMerchantApi,
  CrewMerchantUi,
} from 'corso-types';

import { generatePath } from 'react-router-dom';
import env from './env';
import { getLoggedInUser } from './loggedInUser';
import { ErrorResponse, isApiError, isErrorMessage } from './utils/error';

type PathParams<Path extends CrewMerchantApi.ApiPath> = Parameters<
  typeof generatePath<Path>
>[1];

type RequestConfig<
  Path extends CrewMerchantApi.ApiPath,
  Method extends CrewMerchantApi.MethodOfPath<Path>,
> = {
  path: Path;
  method: Method;
  params: PathParams<Path>;
  loggedInUserName?: string;
} & (CrewMerchantApi.RequestBody<Path, Method> extends never ?
  {
    body?: never;
  }
: {
    body: CrewMerchantApi.RequestBody<Path, Method>;
  }) &
  (CrewMerchantApi.RequestQuery<Path, Method> extends never ?
    {
      query?: never;
    }
  : {
      query: CrewMerchantApi.RequestQuery<Path, Method>;
    });

async function parseResponse<Data>(response: Response) {
  const clone = response.clone(); // clone the response to read the body if it's already been read
  const body: unknown = await clone.json(); // may throw SyntaxError if the body is not valid JSON
  if (response.ok) return body as Data; // trust the API we've defined

  // TODO fix error responses to be consistent from the API
  // TODO extend API error to support full Zod errors
  // ! only throw errors that are defined in the API to be handled by the createQuery
  if (isApiError(body))
    throw new ErrorResponse(body.clientMessage, response, body);
  if (isErrorMessage(body))
    throw new ErrorResponse(body.message, response, body);

  // TODO handle API errors more consistently
  throw new ErrorResponse(
    'Something went wrong, please try again later.',
    response,
    body,
  );
}

type MutationRequiresLoggedInUserName<
  Path extends CrewMerchantApi.ApiPath,
  Method extends CrewMerchantApi.MethodOfPath<Path>,
> =
  Path extends (
    | `/:storeId/claim`
    | `/:storeId/dashboard/prompt`
    | `/:storeId/claim/:claimId`
    | `/:storeId/claim/:claimId/${string}`
  ) ?
    Method extends 'post' | 'put' | 'delete' ?
      true
    : false
  : false;

type QueryRequiresLoggedInUserName<
  Path extends CrewMerchantApi.ApiPath,
  Method extends CrewMerchantApi.MethodOfPath<Path>,
> =
  Path extends `/:storeId/dashboard/prompt` ?
    Method extends 'get' ?
      true
    : false
  : false;

// TODO find a way to make this header required as part of the API definition // ? evaluate alternatives that are more flexible for shared request headers; maybe a way to curry this into the request from one place
type RequiresLoggedInUserName<
  Path extends CrewMerchantApi.ApiPath,
  Method extends CrewMerchantApi.MethodOfPath<Path>,
> =
  MutationRequiresLoggedInUserName<Path, Method> extends true ?
    { loggedInUserName: string }
  : QueryRequiresLoggedInUserName<Path, Method> extends true ?
    { loggedInUserName: string }
  : { loggedInUserName?: never };

async function request<
  Path extends CrewMerchantApi.ApiPath,
  Method extends CrewMerchantApi.MethodOfPath<Path>,
>(
  {
    path,
    method,
    params,
    body,
    query,
    loggedInUserName,
  }: RequestConfig<Path, Method> & RequiresLoggedInUserName<Path, Method>,
  fetchInit?: Omit<RequestInit, 'method' | 'body'>,
) {
  const { headers, ...init } = fetchInit ?? {};

  const loggedInUser = getLoggedInUser();

  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...(loggedInUser && {
      [CrewMerchantApi.loggedInUserIdFromPlatform]: loggedInUser,
    }),

    ...(loggedInUserName && {
      [CrewMerchantApi.loggedInUserName]: loggedInUserName,
    }),
  };

  // * this is gross, and alternatives should be considered; however, it encodes params so that application code doesn't have to
  const encodedParams =
    params ?
      (Object.fromEntries(
        Object.entries<
          NonNullable<PathParams<Path>>[keyof NonNullable<PathParams<Path>>]
        >(params).map(([key, value]) => [
          key,
          value ? encodeURIComponent(value) : null,
        ]),
      ) as PathParams<Path>)
    : undefined;

  const url = new URL(
    `${env.VITE_BASE_API_URL}/merchant${generatePath(path, encodedParams)}`,
  );

  if (query) {
    Object.entries<unknown>(query).forEach(([key, value]) => {
      if (value === undefined) return; // omit undefined values from being added to the query string
      url.searchParams.append(key, `${String(value)}`); // ! this coercion is not safe, but query params should must always be interchanged as string values
    });
  }

  const response = await fetch(url.toString(), {
    method,
    body: JSON.stringify(body),
    headers: { ...defaultHeaders, ...headers },
    ...init,
  }).catch((error) => {
    const errorContext = {
      buildSha: env.VITE_BUILD_SHA,
      method,
      url: url.toString(),
      body: JSON.stringify(body),
      headers: { ...defaultHeaders, ...headers },
      ...init,
    };

    if (error instanceof Error) {
      throw new Error(
        `API Request Failed to Fetch: ${error.message}\n${JSON.stringify(errorContext)}`,
        { cause: error },
      );
    }

    // the console.error will trigger a Sentry capture, but the thrown error only will if it fails to be caught
    console.error(
      'API Request Failed to Fetch with Non-Error',
      error,
      errorContext,
    );
    throw new Error('API Request Failed to Fetch with Non-Error', {
      cause: error,
    });
  });

  return parseResponse<CrewMerchantApi.ResponseBody<Path, Method>>(response);
}

/** Abstractions upon HTTP requests with `fetch` with trust-based type-safety on backend types. */
const api = {
  signedUrl: {
    get: (filename: string, contentType: string) =>
      request({
        path: '/signed-url',
        method: 'get',
        params: {}, // TODO determine why and adjust related types so it must be omitted
        query: {
          filename,
          contentType,
        },
      }),
  },
  // ? might better be elsewhere, since it doesn't match any paths provided by the api, breaking that pattern, and it further abstracts/orchestrates logic
  fileUpload: {
    put: async (file: File) => {
      const { signedUploadUrl, url, uploadFilename } = await api.signedUrl.get(
        file.name,
        file.type,
      );
      const response = await fetch(signedUploadUrl, {
        method: 'PUT',
        body: file,
        // Presigned URLs must have this header set when using AWS S3 client on DigitalOcean @see https://stackoverflow.com/questions/66555915/cannot-upload-files-with-acl-public-read-to-digital-ocean-spaces
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      });
      if (response.ok) return { name: uploadFilename, src: url };
      throw new Error(`Error Uploading File: ${file.name}`, {
        cause: response.statusText,
      });
    },
  },
  user: {
    get: (idFromPlatform: string) =>
      request({
        path: '/user/:idFromPlatform',
        method: 'get',
        params: { idFromPlatform },
      }),
    getWithAccessCode: (accessCode: string) =>
      request({
        path: '/users',
        method: 'get',
        params: {},
        query: { accessCode },
      }),
  },
  store: (storeId: string) => ({
    search: (body: CrewMerchantApi.RequestBody<'/:storeId/search', 'post'>) =>
      request({
        path: '/:storeId/search',
        method: 'post',
        params: { storeId },
        body,
      }),

    searchViews: {
      listViews: () =>
        request({
          path: '/:storeId/view',
          method: 'get',
          params: { storeId },
        }),
      createView: (
        body: CrewMerchantApi.RequestBody<'/:storeId/view', 'post'>,
      ) =>
        request({
          path: '/:storeId/view',
          method: 'post',
          params: { storeId },
          body,
        }),
      getView: (viewId: string) =>
        request({
          path: '/:storeId/view/:viewId',
          method: 'get',
          params: { storeId, viewId },
        }),
      updateView: (
        body: CrewMerchantApi.RequestBody<'/:storeId/view/:viewId', 'put'>,
      ) =>
        request({
          path: '/:storeId/view/:viewId',
          method: 'put',
          params: { storeId, viewId: body.id.toString() },
          body,
        }),
      deleteView: (id: number) =>
        request({
          path: '/:storeId/view/:viewId',
          method: 'delete',
          params: { storeId, viewId: `${id}` },
        }),
    },

    report: (body: CrewMerchantApi.RequestBody<'/:storeId/report', 'post'>) =>
      request({
        path: '/:storeId/report',
        method: 'post',
        params: { storeId },
        body,
      }),

    invoices: {
      list: () =>
        request({
          path: '/:storeId/invoice',
          method: 'get',
          params: { storeId },
        }),
    },

    registrations: {
      get: ({ registrationId }: { registrationId: string }) =>
        request({
          path: '/:storeId/registration/:registrationId',
          method: 'get',
          params: { storeId, registrationId },
        }),

      cancel: ({ registrationId }: { registrationId: string }) =>
        request({
          path: '/:storeId/registration/:registrationId',
          method: 'delete',
          params: { storeId, registrationId },
        }),
      update: ({
        registrationId,
        body,
      }: {
        registrationId: string;
        body: CrewMerchantUi.RegistrationUpdate;
      }) =>
        request({
          path: '/:storeId/registration/:registrationId',
          method: 'put',
          params: { storeId, registrationId },
          body,
        }),
    },
    shippingProtection: {
      protectionRates: () =>
        request({
          path: '/:storeId/shipping-protection/rate',
          method: 'get',
          params: { storeId },
        }),
      createProtectionRate: (
        body: CrewMerchantApi.RequestBody<
          '/:storeId/shipping-protection/rate',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/shipping-protection/rate',
          method: 'post',
          params: { storeId },
          body,
        }),

      updateProtectionRate: (
        rateId: string,
        body: CrewMerchantApi.RequestBody<
          '/:storeId/shipping-protection/rate/:rateId',
          'put'
        >,
      ) =>
        request({
          path: '/:storeId/shipping-protection/rate/:rateId',
          method: 'put',
          params: { storeId, rateId },
          body,
        }),

      deleteProtectionRate: (rateId: string) =>
        request({
          path: '/:storeId/shipping-protection/rate/:rateId',
          method: 'delete',
          params: { storeId, rateId },
        }),

      configure: (syncShippingZones: boolean) =>
        request({
          path: '/:storeId/shipping-protection/configure',
          method: 'post',
          params: { storeId },
          body: { syncShippingZones },
        }),
      claimCreate: (
        body: CrewMerchantApi.RequestBody<
          '/:storeId/shipping-protection/claim',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/shipping-protection/claim',
          method: 'post',
          params: { storeId },
          body,
        }),
      claim: (shippingClaimId: string) => ({
        get: () =>
          request({
            path: '/:storeId/shipping-protection/claim/:shippingClaimId',
            method: 'get',
            params: { storeId, shippingClaimId },
          }),

        finalize: (resolutionMethod: ClaimResolutionMethodEnum) =>
          request({
            path: '/:storeId/shipping-protection/claim/:shippingClaimId/finalize',
            method: 'post',
            params: { storeId, shippingClaimId },
            body: { resolutionMethod },
          }),
      }),
    },
    dashboard: {
      load: ({
        email,
        firstName,
        lastName,
        dashboardId,
      }: CrewMerchantApi.RequestQuery<'/:storeId/dashboard/load', 'get'>) =>
        request({
          path: '/:storeId/dashboard/load',
          method: 'get',
          query: { email, firstName, lastName, dashboardId },
          params: { storeId },
        }),
      list: () =>
        request({
          path: '/:storeId/dashboard',
          method: 'get',

          params: { storeId },
        }),
      prompt: (
        query: CrewMerchantApi.RequestQuery<
          '/:storeId/dashboard/prompt',
          'get'
        >,
        loggedInUserName: string,
      ) =>
        request({
          path: '/:storeId/dashboard/prompt',
          method: 'get',
          params: { storeId },
          query,
          loggedInUserName,
        }),
    },
    order: {
      get: (query: CrewMerchantApi.RequestQuery<'/:storeId/order', 'get'>) =>
        request({
          path: '/:storeId/order',
          method: 'get',
          params: { storeId },
          query,
        }),
    },
    claimCreate: (
      claimCreate: CrewMerchantApi.RequestBody<'/:storeId/claim', 'post'>,
      loggedInUserName: string,
    ) =>
      request({
        path: '/:storeId/claim',
        method: 'post',
        params: { storeId },
        body: claimCreate,
        loggedInUserName,
      }),
    claim: (claimId: string, loggedInUserName: string) => ({
      get: () =>
        request({
          path: '/:storeId/claim/:claimId',
          method: 'get',
          params: { storeId, claimId },
        }),
      finalize: (
        claimFinalize: CrewMerchantApi.RequestBody<
          '/:storeId/claim/:claimId/finalize',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/claim/:claimId/finalize',
          method: 'post',
          body: claimFinalize,
          params: { storeId, claimId },
          loggedInUserName,
        }),

      quoteAndCreateShipment: (
        shipmentCreate: CrewMerchantApi.RequestBody<
          '/:storeId/claim/:claimId/shipment/quote-and-create',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/claim/:claimId/shipment/quote-and-create',
          method: 'post',
          body: shipmentCreate,
          params: { storeId, claimId },
          loggedInUserName,
        }),

      createShipment: (
        shipmentCreate: CrewMerchantApi.RequestBody<
          '/:storeId/claim/:claimId/shipment',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/claim/:claimId/shipment',
          method: 'post',
          body: shipmentCreate,
          params: { storeId, claimId },
          loggedInUserName,
        }),
      createShipmentQuote: (
        shipmentQuoteCreate: CrewMerchantApi.RequestBody<
          '/:storeId/claim/:claimId/shipment/quote',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/claim/:claimId/shipment/quote',
          method: 'post',
          body: shipmentQuoteCreate,
          params: { storeId, claimId },
          loggedInUserName,
        }),
      close: () =>
        request({
          path: '/:storeId/claim/:claimId/close',
          method: 'post',

          params: { storeId, claimId },
          loggedInUserName,
        }),
      requestInfo: (
        body: CrewMerchantApi.RequestBody<
          '/:storeId/claim/:claimId/request-info',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/claim/:claimId/request-info',
          method: 'post',
          params: { storeId, claimId },
          body,
          loggedInUserName,
        }),
      updateCustomerInfo: (
        body: CrewMerchantApi.RequestBody<
          '/:storeId/claim/:claimId/customer-info',
          'put'
        >,
      ) =>
        request({
          path: '/:storeId/claim/:claimId/customer-info',
          method: 'put',
          params: { storeId, claimId },
          body,
          loggedInUserName,
        }),

      updateFees: (
        body: CrewMerchantApi.RequestBody<
          '/:storeId/claim/:claimId/fees',
          'put'
        >,
      ) =>
        request({
          path: '/:storeId/claim/:claimId/fees',
          method: 'put',
          params: { storeId, claimId },
          body,
          loggedInUserName,
        }),
      addTimelineEvent: (
        body: CrewMerchantApi.RequestBody<
          '/:storeId/claim/:claimId/timeline',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/claim/:claimId/timeline',
          method: 'post',
          params: { storeId, claimId },
          body,
          loggedInUserName,
        }),
      tags: {
        list: async () =>
          request({
            path: '/:storeId/claim/:claimId/tags',
            method: 'get',
            params: { storeId, claimId },
          }),
        upsert: async (
          body: CrewMerchantApi.RequestBody<
            '/:storeId/claim/:claimId/tags',
            'put'
          >,
        ) =>
          request({
            path: '/:storeId/claim/:claimId/tags',
            method: 'put',
            params: { storeId, claimId },
            body,
            loggedInUserName,
          }),
      },
      lineItem: (claimLineItemId: string) => ({
        inspection: {
          create: (
            body: CrewMerchantApi.RequestBody<
              '/:storeId/claim/:claimId/line-item/:claimLineItemId/inspection',
              'post'
            >,
          ) =>
            request({
              path: '/:storeId/claim/:claimId/line-item/:claimLineItemId/inspection',
              method: 'post',
              params: { storeId, claimId, claimLineItemId },
              body,
              loggedInUserName,
            }),
          delete: (claimInspectionId: string) =>
            request({
              path: '/:storeId/claim/:claimId/line-item/:claimLineItemId/inspection/:claimInspectionId',
              method: 'delete',
              params: { storeId, claimId, claimLineItemId, claimInspectionId },
              loggedInUserName,
            }),

          settings: () =>
            request({
              path: '/:storeId/claim/:claimId/line-item/:claimLineItemId/inspection/settings',
              method: 'get',
              params: { storeId, claimId, claimLineItemId },
            }),
        },
      }),
    }),

    claimReason: {
      get: () =>
        request({
          path: '/:storeId/claim-reason',
          method: 'get',
          params: { storeId },
        }),
      create: (
        body: CrewMerchantApi.RequestBody<'/:storeId/claim-reason', 'post'>,
      ) =>
        request({
          path: '/:storeId/claim-reason',
          method: 'post',
          params: { storeId },
          body,
        }),
      update: (
        claimReasonId: string,
        body: CrewMerchantApi.RequestBody<
          '/:storeId/claim-reason/:claimReasonId',
          'put'
        >,
      ) =>
        request({
          path: '/:storeId/claim-reason/:claimReasonId',
          method: 'put',
          params: { storeId, claimReasonId },
          body,
        }),
      groups: {
        get: () =>
          request({
            path: '/:storeId/claim-reason/group',
            method: 'get',
            params: { storeId },
          }),
        update: (
          body: CrewMerchantApi.RequestBody<
            '/:storeId/claim-reason/group/:claimReasonGroupId',
            'put'
          >,
        ) =>
          request({
            path: '/:storeId/claim-reason/group/:claimReasonGroupId',
            method: 'put',
            params: { storeId, claimReasonGroupId: String(body.id) },
            body,
          }),
        create: (
          body: CrewMerchantApi.RequestBody<
            '/:storeId/claim-reason/group',
            'post'
          >,
        ) =>
          request({
            path: '/:storeId/claim-reason/group',
            method: 'post',
            params: { storeId },
            body,
          }),
        delete: (claimReasonGroupId: string) =>
          request({
            path: '/:storeId/claim-reason/group/:claimReasonGroupId',
            method: 'delete',
            params: { storeId, claimReasonGroupId },
          }),
      },
    },

    storeUser: {
      get: () =>
        request({
          path: '/:storeId/user',
          method: 'get',
          params: { storeId },
        }),
      signUp: (
        body: CrewMerchantApi.RequestBody<'/:storeId/user/sign-up', 'post'>,
      ) =>
        request({
          path: '/:storeId/user/sign-up',
          method: 'post',
          params: { storeId },
          body,
        }),
      create: (body: CrewMerchantApi.RequestBody<'/:storeId/user', 'post'>) =>
        request({
          path: '/:storeId/user',
          method: 'post',
          params: { storeId },
          body,
        }),

      update: (
        userId: string,
        body: CrewMerchantApi.RequestBody<'/:storeId/user/:userId', 'put'>,
      ) =>
        request({
          path: '/:storeId/user/:userId',
          method: 'put',
          params: { storeId, userId },
          body,
        }),

      delete: (userId: string) =>
        request({
          path: '/:storeId/user/:userId',
          method: 'delete',
          params: { storeId, userId },
        }),

      resetPassword: (
        body: CrewMerchantApi.RequestBody<
          '/:storeId/user/:userId/reset-password',
          'post'
        >,
      ) =>
        request({
          path: '/:storeId/user/:userId/reset-password',
          method: 'post',
          params: { storeId, userId: body.id.toString() },
          body,
        }),
    },
    payment: {
      refund: (customerPaymentId: string) =>
        request({
          path: '/:storeId/customer-payment/:customerPaymentId/refund',
          method: 'post',
          params: { storeId, customerPaymentId },
        }),
    },
    settings: {
      config: {
        get: () =>
          request({
            path: '/:storeId/settings/config',
            method: 'get',
            params: { storeId },
          }),
        put: (
          body: CrewMerchantApi.RequestBody<'/:storeId/settings/config', 'put'>,
        ) =>
          request({
            path: '/:storeId/settings/config',
            method: 'put',
            params: { storeId },
            body,
          }),
      },
      integrations: {
        get: () =>
          request({
            path: '/:storeId/settings/integrations',
            method: 'get',
            params: { storeId },
          }),
        update: (
          body: CrewMerchantApi.RequestBody<
            '/:storeId/settings/integrations',
            'put'
          >,
        ) =>
          request({
            path: '/:storeId/settings/integrations',
            method: 'put',
            params: { storeId },
            body,
          }),
      },
      theme: {
        get: () =>
          request({
            path: '/:storeId/settings/theme',
            method: 'get',
            params: { storeId },
          }),
        update: (
          body: CrewMerchantApi.RequestBody<'/:storeId/settings/theme', 'put'>,
        ) =>
          request({
            path: '/:storeId/settings/theme',
            method: 'put',
            params: { storeId },
            body,
          }),
      },
    },
    factValues: {
      get: () =>
        request({
          path: '/:storeId/fact/values',
          method: 'get',
          params: { storeId },
        }),
    },
    storeClaimTags: {
      list: () =>
        request({
          path: '/:storeId/claim/tags',
          method: 'get',
          params: { storeId },
        }),
      create: (body: { name: string }) =>
        request({
          path: '/:storeId/claim/tags',
          method: 'post',
          params: { storeId },
          body,
        }),
      delete: (tagId: string) =>
        request({
          path: '/:storeId/claim/tags/:tagId',
          method: 'delete',
          params: { storeId, tagId },
        }),
    },
    storeRules: {
      list: () =>
        request({ path: '/:storeId/rule', method: 'get', params: { storeId } }),
      get: (ruleId: string) =>
        request({
          path: '/:storeId/rule/:ruleId',
          method: 'get',
          params: { storeId, ruleId },
        }),
      create: (body: CrewMerchantApi.RequestBody<'/:storeId/rule', 'post'>) =>
        request({
          path: '/:storeId/rule',
          method: 'post',
          params: { storeId },
          body,
        }),
      update: (
        ruleId: string,
        body: CrewMerchantApi.RequestBody<'/:storeId/rule/:ruleId', 'put'>,
      ) =>
        request({
          path: '/:storeId/rule/:ruleId',
          method: 'put',
          params: { storeId, ruleId },
          body,
        }),
      delete: (ruleId: string) =>
        request({
          path: '/:storeId/rule/:ruleId',
          method: 'delete',
          params: { storeId, ruleId },
        }),
    },
    returnLocation: {
      get: () =>
        request({
          path: '/:storeId/return-location',
          method: 'get',
          params: { storeId },
        }),
      create: (
        body: CrewMerchantApi.RequestBody<'/:storeId/return-location', 'post'>,
      ) =>
        request({
          path: '/:storeId/return-location',
          method: 'post',
          params: { storeId },
          body,
        }),
      update: (
        returnLocationId: string,
        body: CrewMerchantApi.RequestBody<
          '/:storeId/return-location/:returnLocationId',
          'put'
        >,
      ) =>
        request({
          path: '/:storeId/return-location/:returnLocationId',
          method: 'put',
          params: { storeId, returnLocationId },
          body,
        }),
      delete: (returnLocationId: string) =>
        request({
          path: '/:storeId/return-location/:returnLocationId',
          method: 'delete',
          params: { storeId, returnLocationId },
        }),
    },

    // ? maybe move into the product object
    variantExchangeOptions: (idFromPlatform: string) =>
      request({
        path: '/:storeId/product/:idFromPlatform',
        method: 'get',
        params: { storeId, idFromPlatform },
      }),
    product: {
      search: (searchTerm: string) =>
        request({
          path: '/:storeId/product',
          method: 'get',
          params: { storeId },
          query: { searchTerm },
        }),

      types: () =>
        request({
          path: '/:storeId/product/type',
          method: 'get',
          params: { storeId },
        }),

      tags: () =>
        request({
          path: '/:storeId/product/tag',
          method: 'get',
          params: { storeId },
        }),
      collections: () =>
        request({
          path: '/:storeId/product/collection',
          method: 'get',
          params: { storeId },
        }),
    },
    productGroup: {
      get: () =>
        request({
          path: '/:storeId/product/group',
          method: 'get',
          params: { storeId },
        }),
      update: (
        groupId: string,
        body: CrewMerchantApi.RequestBody<
          '/:storeId/product/group/:groupId',
          'put'
        >,
      ) =>
        request({
          path: '/:storeId/product/group/:groupId',
          method: 'put',
          params: { storeId, groupId },
          body,
        }),
      delete: (groupId: string) =>
        request({
          path: '/:storeId/product/group/:groupId',
          method: 'delete',
          params: { storeId, groupId },
        }),
      create: (
        body: CrewMerchantApi.RequestBody<'/:storeId/product/group', 'post'>,
      ) =>
        request({
          path: '/:storeId/product/group',
          method: 'post',
          params: { storeId },
          body,
        }),

      listProducts: (groupId: string) =>
        request({
          path: '/:storeId/product/group/:groupId/list-products',
          method: 'get',
          params: { storeId, groupId },
        }),

      refreshProduct: (groupId: string) =>
        request({
          path: '/:storeId/product/group/:groupId/refresh-products',
          method: 'post',
          params: { storeId, groupId },
        }),
    },
    customFields: {
      get: () =>
        request({
          path: '/:storeId/custom-field',
          method: 'get',
          params: { storeId },
        }),
      update: (
        customFieldId: string,
        body: CrewMerchantApi.RequestBody<
          '/:storeId/custom-field/:customFieldId',
          'put'
        >,
      ) =>
        request({
          path: '/:storeId/custom-field/:customFieldId',
          method: 'put',
          params: { storeId, customFieldId },
          body,
        }),
      delete: (customFieldId: string) =>
        request({
          path: '/:storeId/custom-field/:customFieldId',
          method: 'delete',
          params: { storeId, customFieldId },
        }),
      create: (
        body: CrewMerchantApi.RequestBody<'/:storeId/custom-field', 'post'>,
      ) =>
        request({
          path: '/:storeId/custom-field',
          method: 'post',
          params: { storeId },
          body,
        }),
    },
  }),
};

export default api;
