import {
  faCircleInfo,
  faClone,
  faPen,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { CrewMerchantUi } from 'corso-types';
import { ComponentProps, ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmModal from '~/components/ConfirmModal';
import { TextInput } from '~/components/field';
import Icon from '~/components/Icon';
import Modal from '~/components/Modal';
import SpinnerIcon from '~/components/SpinnerIcon';
import { Button } from '~/components/ui/primitives/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/primitives/DropdownMenu';
import { cn } from '~/utils/shadcn';
import { LOCAL_VIEW_ID } from './useSearchControlBarState';
import {
  useCreateSearchView,
  useDeleteSearchView,
  useSearchViews,
  useUpdateSearchView,
} from './useSearchViewApi';

const activeViewStyles = 'bg-neutral-300/50 text-neutral-900';

type ViewFormModalProps = {
  title: string;
  show: boolean;
  onCancel: () => void;
  onConfirm: (values: { name: string }) => void;
  confirmText: string;
  defaultValues?: { name?: string };
  pending?: boolean;
};

function ViewFormModal({
  title,
  show,
  onCancel: onClose,
  onConfirm,
  confirmText: onConfirmLabel,
  defaultValues,
  pending: loading,
}: ViewFormModalProps) {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: defaultValues?.name ?? '',
    },
  });

  const name = watch('name');

  return (
    <Modal
      title={title}
      show={show}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" form="view-form" variant="primary">
            {loading && <SpinnerIcon />}
            {onConfirmLabel}
          </Button>
        </>
      }
    >
      <form
        id="view-form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onConfirm)}
      >
        <TextInput
          required
          label="Name"
          id="name"
          addon={{
            insideEnd: (
              <div className="flex items-center gap-1">
                <p className="text-xs text-corso-gray-500">{`${name.length}/40`}</p>
              </div>
            ),
          }}
          {...register('name', {
            required: 'Name is required',
            maxLength: {
              value: 40,
              message: 'Name must be less than 40 characters',
            },
          })}
          error={errors.name?.message}
        />
      </form>
    </Modal>
  );
}

export function CreateView({
  view,
  onCreate,
  triggerContent,
  triggerVariant = 'default',
  triggerSize = 'default',
  triggerIcon = false,
}: {
  view: Omit<CrewMerchantUi.SearchView, 'id' | 'name'>;
  onCreate: (view: CrewMerchantUi.SearchView) => void;
  triggerContent: ReactNode;
  triggerVariant?: ComponentProps<typeof Button>['variant'];
  triggerSize?: ComponentProps<typeof Button>['size'];
  triggerIcon?: ComponentProps<typeof Button>['icon'];
}) {
  const [show, setShow] = useState(false);
  const { mutate: createView, isPending } = useCreateSearchView((newView) => {
    onCreate(newView);
    setShow(false);
  });

  return (
    <>
      <Button
        variant={triggerVariant}
        onClick={() => setShow(true)}
        className="min-h-8 text-xs"
        size={triggerSize}
        icon={triggerIcon}
      >
        {triggerContent}
      </Button>

      <ViewFormModal
        title="Create new view"
        show={show}
        onCancel={() => setShow(false)}
        confirmText="Create view"
        onConfirm={(values) => {
          createView({ ...view, ...values });
        }}
        pending={isPending}
      />
    </>
  );
}

function ActiveViewMenu({
  view,
  onEdit,
  onChange,
}: {
  view: CrewMerchantUi.SearchView;
  onEdit: (view: CrewMerchantUi.SearchView) => void;
  onChange: (view: CrewMerchantUi.SearchView | null) => void;
}) {
  const [showRename, setShowRename] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const { mutate: updateView, isPending: isUpdatePending } =
    useUpdateSearchView((updatedView) => {
      onChange(updatedView);
      setShowRename(false);
    });
  const { mutate: duplicateView, isPending: isDuplicatePending } =
    useCreateSearchView((newView) => {
      onChange(newView);
      setShowDuplicate(false);
    });
  const { mutate: deleteView, isPending: isDeletePending } =
    useDeleteSearchView(() => {
      onChange(null);
      setShowDelete(false);
    });

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className={cn('min-h-8 gap-2 text-xs', activeViewStyles)}
            aria-current
          >
            {view.name} <Icon className="size-3" icon={faChevronDown} />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="start">
          <DropdownMenuItem
            className="gap-2"
            onSelect={() => setShowRename(true)}
          >
            <Icon icon={faCircleInfo} />
            Rename view
          </DropdownMenuItem>
          <DropdownMenuItem
            className="gap-2"
            onSelect={() => setShowDuplicate(true)}
          >
            <Icon icon={faClone} />
            Duplicate view
          </DropdownMenuItem>
          <DropdownMenuItem className="gap-2" onSelect={() => onEdit(view)}>
            <Icon icon={faPen} />
            Edit view
          </DropdownMenuItem>
          <DropdownMenuItem
            className="gap-2 text-corso-red-700 focus:bg-corso-red-100 focus:text-red-700"
            onSelect={() => setShowDelete(true)}
          >
            <Icon icon={faTrashCan} />
            Delete view
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <ViewFormModal
        title="Rename view"
        show={showRename}
        onCancel={() => setShowRename(false)}
        confirmText="Rename view"
        defaultValues={{ name: view.name }}
        onConfirm={(values) => updateView({ ...view, name: values.name })}
        pending={isUpdatePending}
      />

      <ViewFormModal
        title="Duplicate view"
        show={showDuplicate}
        onCancel={() => setShowDuplicate(false)}
        confirmText="Duplicate view"
        defaultValues={{ name: `Copy of ${view.name}` }}
        onConfirm={(values) => {
          const { id, ...toCopy } = view;
          duplicateView({ ...toCopy, name: values.name });
        }}
        pending={isDuplicatePending}
      />

      <ConfirmModal
        title="Delete view"
        show={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => deleteView(view.id)}
        confirmText="Delete view?"
        variant="destructive"
        prompt={`This cannot be undone. ${view.name} will no longer be available.`}
        loading={isDeletePending}
      />
    </>
  );
}

type ViewsProps = {
  entity: CrewMerchantUi.SearchView['kind'];
  selectedView: CrewMerchantUi.SearchView;
  onViewSelect: (view: CrewMerchantUi.SearchView | null) => void;
  onViewEdit: (view: CrewMerchantUi.SearchView) => void;
  className?: string;
};

export function Views({
  entity,
  className,
  selectedView,
  onViewEdit,
  onViewSelect,
}: ViewsProps) {
  const { data: views } = useSearchViews(entity);

  return (
    <div className={cn('flex flex-grow gap-2', className)}>
      <Button
        className={cn(
          'min-h-8 text-xs',
          selectedView.id === LOCAL_VIEW_ID &&
            'bg-neutral-300/50 text-neutral-900',
        )}
        variant="ghost"
        aria-current={selectedView.id === LOCAL_VIEW_ID}
        onClick={() => onViewSelect(null)}
      >
        All
      </Button>
      {views?.map((view) =>
        selectedView && view.id === selectedView.id ?
          <ActiveViewMenu
            view={selectedView}
            onChange={onViewSelect}
            onEdit={onViewEdit}
            // want to rerender if the view is renamed  so the duplicate name value is updated
            key={`${view.id}-${view.name}`}
          />
        : <Button
            className="min-h-8 text-xs"
            variant="ghost"
            onClick={() => onViewSelect(view)}
            key={view.id}
          >
            {view.name}
          </Button>,
      )}

      <CreateView
        view={{ kind: entity, filters: {} }}
        onCreate={onViewSelect}
        triggerContent={
          <Icon
            icon={faPlus}
            className="size-4"
            accessibilityLabel="Add View"
          />
        }
        triggerVariant="ghost"
        triggerIcon
      />
    </div>
  );
}
