import { useQuery } from '@tanstack/react-query';
import { CrewMerchantApi } from 'corso-types';
import { useMemo } from 'react';
import api from '~/api';
import { useStoreId } from '~/hooks/useStoreId';

type Search = Omit<
  CrewMerchantApi.RequestBody<'/:storeId/search', 'post'>,
  'take'
>;

const useSearchData = (search: Search) => {
  const storeId = useStoreId();
  const query = useQuery({
    queryKey: ['search', search, storeId],
    queryFn: async () =>
      api.store(storeId).search({
        take: 50,
        ...search,
      }),
    // prevents the table from flickering when updating
    placeholderData: (previous) => previous,
  });

  return useMemo(
    () => ({
      ...query,
      isSearchPending: query.isFetching,
      data: query.data?.data ?? [],
      pageInfo: query.data?.pageInfo ?? { prevCursor: null, nextCursor: null },
    }),
    [query],
  );
};

export default useSearchData;
