import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { NestedTracker, useIsNested } from './NestedTracker';

/**
 * Roughly inspired by the different cards in Tailwind UI, where this is only really a very simple and basic card.
 * They originally had called them panels, but are now called cards.
 *
 * @see https://tailwindui.com/components/application-ui/layout/cards
 *
 * Cards grow to fill the space they are given, but their content is restricted within them, so anything that would overflow will scroll.
 * When nested, only the outermost card in the stack will have a shadow.
 */
export default function Card({
  flush = false,
  children,
}: PropsWithChildren<{
  /** Flush cards allow their content to fill up to the edge, so they have no padding. */
  // TODO consider alternative names
  flush?: boolean;
}>) {
  const isNested = useIsNested('card');
  return (
    <section
      className={twMerge(
        'flex flex-col gap-4 overflow-auto rounded-lg border bg-white',
        !flush && 'px-3 py-5 lg:p-4',
        !isNested && 'shadow',
      )}
    >
      <NestedTracker nest="card">{children}</NestedTracker>
    </section>
  );
}

// TODO consider moving to shared type
type PropsWithNonElementChildren<P = unknown> = P & {
  children?: Exclude<ReactNode, ReactElement> | undefined;
};

function CardHeading({
  as: Component = 'p',
  children,
}: PropsWithNonElementChildren<{
  // TODO consider alternative to an `as` prop while still having a limited set of children
  as?: React.ElementType<
    { className?: string },
    'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' // primarily prose elements
  >;
}>) {
  return (
    <Component className="text-sm font-bold text-corso-gray-900 opacity-75">
      {children}
    </Component>
  );
}
Card.Heading = CardHeading;

function CardDescription({ children }: PropsWithNonElementChildren) {
  return <p className="text-pretty text-sm text-corso-gray-500">{children}</p>;
}
Card.Description = CardDescription;
