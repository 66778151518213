import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Label } from '~/components/ui/primitives/Label';
import { FieldGroup } from './FieldGroup';
import SupportingText from './SupportingText';

function InsideAddon({
  children,
  position = 'end',
}: PropsWithChildren<{ position?: 'start' | 'end' }>) {
  return (
    <div
      className={twMerge(
        'flex select-none items-center text-sm text-corso-gray-500 peer-[:invalid]:text-corso-red-600',
        position === 'start' ? 'order-first' : 'order-last',
      )}
    >
      {children}
    </div>
  );
}

type InputValue = Exclude<
  InputHTMLAttributes<HTMLInputElement>['value'],
  undefined
> | null;

export type InputProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'ref' | 'value' | 'id' | 'children' // children isn't allowed as `input` is a void element, why React allows it is beyond me
> & {
  /**
   * Required `id` to be associated with the `input` element.
   * Also used to derive further `id` attributes for the wrappers of the `description` and `error` elements.
   * These derived `id` attributes are then and used for the `aria-describedby` attribute on the primary `input` element.
   */
  // TODO allow optional id via `useId` hook fallback
  id: string;
  label: string;
  labelVisuallyHidden?: boolean;
  value?: InputValue;
  /**
   * Descriptive information to further clarify the intended data.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with multiple paragraphs for simplicity.
   */
  details?: ReactNode;
  /**
   * Descriptive information about the `error` state of the `input`.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with multiple paragraphs for simplicity.
   */
  error?: ReactNode;
  /**
   * Adornments/addons to be placed alongside `input` to the left/leading/start and right/trailing/end sides either inside or outside the border of the `input`.
   * If an `insideStart` or `insideEnd` addon is a `string` or `number`, it will be wrapped as `LiteralAddon` through a `InsideAddon` with some appropriate text-styling; otherwise, it'll just render the addon.
   * If an `outsideStart` or `outsideEnd` they will be put outside the `input`, and on mobile this will wrap to either before/after the input in a column and on desktop these will be in a row.
   */
  addon?: {
    outsideStart?: ReactNode;
    insideStart?: ReactNode;
    insideEnd?: ReactNode;
    outsideEnd?: ReactNode;
  };
};

// * maybe someday this could support input masking
// ? might need support to customize the colors of the different states and the associated wrappers
const Input = forwardRef(
  (
    {
      id,
      label,
      labelVisuallyHidden = false,
      value,
      details,
      error,
      addon: {
        insideStart = null,
        insideEnd = null,
        outsideStart = null,
        outsideEnd = null,
      } = {},
      // additional native input props, extracted for extra usage and then propagated to the `input` element
      type,
      required,
      hidden,
      className,
      onChange,
      ...rest
    }: InputProps,
    ref: Ref<HTMLInputElement | null>,
  ) => {
    const detailsId = `${id}-details`;
    const errorId = `${id}-error`;
    const inputRef = useRef<HTMLInputElement>(null);

    const [nativeError, setNativeError] = useState<string | null>(null);
    useImperativeHandle(ref, () => inputRef.current);

    //* syncing the external error state with the input's validity state since the validation is happening outside of the input
    useEffect(() => {
      const input = inputRef.current;
      const preventDefault = (e: Event) => {
        e.preventDefault();
      };

      if (input) {
        //* prevents the invalid pop-up from showing that displays the browser's default error message
        input.addEventListener('invalid', preventDefault);
        //* the validity message does not matter here since we are preventing the default
        input.setCustomValidity(error ? 'Invalid' : '');
      }
      return () => {
        input?.removeEventListener('invalid', preventDefault);
      };
    }, [error]);

    return (
      <FieldGroup className={className} data-testid={id} hidden={hidden}>
        <div
          className={twMerge(
            'flex items-center justify-between',
            labelVisuallyHidden && 'sr-only', // applied to label group wrapper
          )}
        >
          <Label htmlFor={id}>{label}</Label>
          {required ?
            <span className="sr-only">Required</span>
          : <SupportingText>Optional</SupportingText>}
        </div>
        <div className="flex flex-col gap-2 md:flex-row">
          {outsideStart}
          <div
            className={twMerge(
              // base styles for default/neutral state
              'flex w-full gap-1 rounded-md px-3 py-1.5 text-sm leading-6 text-corso-gray-800 shadow-sm ring-1 ring-inset ring-corso-gray-300 focus-within:ring-2 focus-within:ring-corso-blue-600',
              // disabled styles; the `disabled:`
              'has-[:disabled]:cursor-not-allowed has-[:disabled]:bg-corso-gray-50 has-[:disabled]:text-corso-gray-500 has-[:disabled]:ring-corso-gray-200',
              // error state style overrides
              'has-[:user-invalid]:text-corso-red-900 has-[:user-invalid]:ring-corso-red-300 has-[:user-invalid]:focus-within:ring-corso-red-600',
            )}
          >
            <input
              {...rest}
              onChange={(e) => {
                onChange?.(e);
                setNativeError(null);
                e.currentTarget.checkValidity();
              }}
              onBlur={(e) => e.currentTarget.checkValidity()}
              onInvalid={(e) =>
                setNativeError(e.currentTarget.validationMessage)
              }
              id={id}
              className={twMerge(
                'peer w-full flex-1 text-ellipsis border-none bg-transparent p-0 text-sm placeholder:text-corso-gray-400 focus:ring-0',
                // error state styles
                '[&:user-invalid]:text-corso-red-900 [&:user-invalid]:ring-corso-red-300 [&:user-invalid]:placeholder:text-corso-red-300 [&:user-invalid]:focus:ring-corso-red-600',
                // file selector button styles
                'file:rounded-md file:border-0 file:bg-transparent file:text-corso-gray-800 file:ring-1 file:ring-inset file:ring-corso-gray-300 focus:outline-0 file:focus:ring-2 file:focus:ring-corso-blue-600 file:[&:user-invalid]:text-corso-red-900 file:[&:user-invalid]:ring-corso-red-300 file:[&:user-invalid]:focus:ring-corso-red-600',
              )}
              // combine `id` into a space-delimited string
              aria-describedby={[details && detailsId, error && errorId]
                .filter(Boolean)
                .join(' ')}
              type={type}
              required={required}
              ref={inputRef}
              // `value` can be `null` to represent an explicitly empty state (e.g. for controlled components); however, if `undefined`, it'll be treated as an uncontrolled component
              value={value !== undefined ? (value ?? '') : undefined}
            />

            {/* Addons are after the input to take advantage of the tailwind peer modifier */}
            {insideStart && (
              <InsideAddon position="start">{insideStart}</InsideAddon>
            )}
            {insideEnd && <InsideAddon position="end">{insideEnd}</InsideAddon>}
          </div>
          {outsideEnd}
        </div>
        {error && (
          <SupportingText error id={errorId}>
            {error}
          </SupportingText>
        )}
        {!error && !!nativeError?.length && nativeError !== 'Invalid' && (
          <SupportingText error id={errorId}>
            {nativeError}
          </SupportingText>
        )}

        {details && <SupportingText id={detailsId}>{details}</SupportingText>}
      </FieldGroup>
    );
  },
);

export default Input;

/**
 * An `input` of a specific type.
 * Maintains setting `value` to `null`; this falls back to an empty string for explicitly empty states.
 */
export type InputTypeProps<
  V extends InputProps['value'],
  ForceControlled extends boolean = false,
> = Omit<InputProps, 'type' | 'value'> &
  (ForceControlled extends true ? { value: V | null } : { value?: V | null });
