import {
  faClipboard,
  faClipboardCheck,
} from '@fortawesome/pro-light-svg-icons';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { useToast } from '~/hooks/useToast';
import { Action } from './ui/Action';

/** Sets a value to to true for a duration. */
function useDelayToggle(duration: number) {
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (!value) return;

    const timer = setTimeout(() => {
      setValue(false);
    }, duration);

    /* eslint-disable-next-line consistent-return */ // have an inconsistent return to avoid always creating a time to just clear it right away
    return () => clearTimeout(timer);
  }, [duration, value]);

  const trigger = useCallback(() => {
    setValue(true);
  }, []);

  return [value, trigger] as const;
}

export default function ClipboardButton({
  onClick,
  variant,
}: {
  onClick?: () => string;
  variant?: ComponentProps<typeof Action>['variant'];
}) {
  const [, copyToClipboard] = useCopyToClipboard();
  const [copiedSuccess, triggerCopiedSuccess] = useDelayToggle(1000);

  const toast = useToast();

  const clipBoardHandler = useCallback(async () => {
    if (!onClick) return;
    await copyToClipboard(onClick());
    triggerCopiedSuccess();
  }, [copyToClipboard, onClick, triggerCopiedSuccess]);

  return (
    <Action
      accessibilityLabel="Copy to clipboard"
      icon={copiedSuccess ? faClipboardCheck : faClipboard}
      variant={variant}
      onClick={() => {
        clipBoardHandler().catch((error) => console.error(error));
        toast.show('Copied to clipboard');
      }}
    />
  );
}
