import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { Action } from '~/components/ui/Action';
import { useStoreRuleUpsert } from '~/hooks/useStoreRules';
import { StoreRule } from '~/types';

export default function StoreRuleDuplicate({
  storeRule,
}: {
  storeRule: StoreRule;
}) {
  const { mutate: upsertStoreRule } = useStoreRuleUpsert();

  const handleDuplicate = () => {
    const { id, rule, ...newRule } = storeRule;

    const { event } = rule;

    upsertStoreRule({
      ...newRule,
      isEnabled: false,
      name: `${storeRule.name} (Copy)`,
      rule: {
        ...rule,
        event: {
          ...event,
          // ! ensure we clear the storeRuleId when duplicating
          storeRuleId: undefined,
        },
      },
    });
  };

  return (
    <Action
      accessibilityLabel={`Duplicate ${storeRule.name} Rule`}
      icon={DocumentDuplicateIcon}
      onClick={() => handleDuplicate()}
    />
  );
}
