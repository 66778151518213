import { useEffect } from 'react';
import { NavLink, useLocation, useRouteError } from 'react-router-dom';
import FullPageStatus from '~/components/FullPageStatus';
import { Button } from '~/components/ui/primitives/Button';

export default function UnhandledError() {
  const location = useLocation();
  const routeError = useRouteError(); // ? maybe try to parse to see if we have a user-friendly error available too

  useEffect(() => {
    if (!routeError) return;
    console.error(routeError);
  }, [routeError]);

  return (
    <FullPageStatus
      title="Something went wrong"
      details="We encountered an unexpected issue. Try reloading this page or contact our support team if the problem persists."
    >
      <div className="flex flex-col items-center justify-center gap-4">
        <Button asChild variant="primary" size="lg">
          <NavLink to={location.pathname} reloadDocument>
            Reload
          </NavLink>
        </Button>
        <Button asChild size="lg">
          <NavLink to="/" reloadDocument>
            Go to Home
          </NavLink>
        </Button>
      </div>
    </FullPageStatus>
  );
}
