import { CrewMerchantUi } from 'corso-types';

import {
  faDhl,
  faFedex,
  faUps,
  faUsps,
} from '@fortawesome/free-brands-svg-icons';
import {
  faFileArrowDown,
  faQrcode,
  faTruck,
} from '@fortawesome/pro-light-svg-icons';
import ButtonGroup from '~/components/ButtonGroup';
import Card from '~/components/Card';
import ClipboardButton from '~/components/ClipboardButton';
import Icon from '~/components/Icon';
import { Action } from '~/components/ui/Action';
import SimpleTooltip from '~/components/ui/SimpleTooltip';

type Shipment = NonNullable<CrewMerchantUi.ClaimShipment>;

const statusLabels = {
  delivered: 'Delivered',
  available_for_pickup: 'Available for Pickup',
  cancelled: 'Cancelled',
  error: 'Error',
  failure: 'Failure',
  in_transit: 'In Transit',
  out_for_delivery: 'Out for Delivery',
  pre_transit: 'Getting Ready To Ship',
  return_to_sender: 'Return to Sender',
  unknown: 'Getting Ready To Ship',
} satisfies Record<
  CrewMerchantUi.ReturnShipmentTracker['latestStatus'],
  string
>;

function CarrierIcon({ carrierName }: { carrierName: string }) {
  const lowerCaseCarrierName = carrierName.toLowerCase();

  if (lowerCaseCarrierName.includes('fedex')) {
    return <Icon icon={faFedex} className="h-6 w-6" />;
  }

  if (lowerCaseCarrierName.includes('usps')) {
    return <Icon icon={faUsps} className="h-6 w-6" />;
  }

  if (lowerCaseCarrierName.includes('ups')) {
    return <Icon icon={faUps} className="h-6 w-6" />;
  }

  if (lowerCaseCarrierName.includes('dhl')) {
    return <Icon icon={faDhl} className="h-6 w-6" />;
  }

  return <Icon icon={faTruck} className="h-6 w-6" />;
}

export default function ShipmentLabel({ shipment }: { shipment: Shipment }) {
  const { tracker, returnShipmentPdfAssetUrl, qrCodeDetail } = shipment;
  if (!tracker) return null;

  const { carrier, latestStatus } = tracker;
  const { trackingCode, name: carrierName, trackingUrl } = carrier;

  const hasDownloadUrl = returnShipmentPdfAssetUrl || qrCodeDetail?.url;
  const qrCodeUrl = qrCodeDetail?.url;
  const labelUrl = returnShipmentPdfAssetUrl;

  return (
    <div className="flex flex-col gap-4">
      {/* some space */}

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <CarrierIcon carrierName={carrierName} />
            <Card.Heading> {carrierName} </Card.Heading>
          </div>
        </div>

        <p className="text-xs text-corso-gray-500">
          {' '}
          {statusLabels[latestStatus]}{' '}
        </p>

        {trackingCode && (
          <div className="flex items-center justify-between">
            <p className="text-sm text-corso-gray-500">
              {' '}
              <a
                className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
                href={
                  trackingUrl ??
                  `https://www.google.com/search?q=${trackingCode}`
                }
                target="_blank"
                rel="noreferrer"
              >
                {trackingCode}
              </a>
            </p>
            <div className="flex gap-2">
              <ClipboardButton onClick={() => trackingCode} variant="ghost" />
            </div>
          </div>
        )}
      </div>

      {hasDownloadUrl && (
        <ButtonGroup horizontal>
          {qrCodeUrl && (
            <SimpleTooltip content="Download QR Code">
              <Action to={qrCodeUrl} accessibilityLabel="Download" download>
                <Icon icon={faQrcode} />
              </Action>
            </SimpleTooltip>
          )}

          {labelUrl && (
            <SimpleTooltip content="Download Label">
              <Action to={labelUrl} accessibilityLabel="Download" download>
                <Icon icon={faFileArrowDown} />
              </Action>
            </SimpleTooltip>
          )}
        </ButtonGroup>
      )}
    </div>
  );
}
