import { CrewMerchantUi, isNullish, stringToJSON } from 'corso-types';
import { endOfDay, startOfDay, sub } from 'date-fns';
import isEqual from 'lodash.isequal';
import { useCallback, useEffect } from 'react';
import { match } from 'ts-pattern';
import { useLocalStorage } from 'usehooks-ts';
import { z } from 'zod';
import { useStoreId } from '~/hooks/useStoreId';

import { SortMenuOption } from './SortMenu';
import {
  DatePreset,
  Filter,
  FilterControlType,
  FilterDefinition,
  useFilterDefinition,
} from './useFilterDefinition';
import { useSearchViews } from './useSearchViewApi';

// todo find a home for this
const useSortOptions = () => [{ value: 'createdOn', label: 'Date' }];

const sortValueSchema = z.object({
  column: z.string(),
  direction: z.enum(['asc', 'desc']),
});
type SortValue = z.infer<typeof sortValueSchema>;

export type State = {
  kind: CrewMerchantUi.SearchView['kind'];
  sortOptions: SortMenuOption[];
  orderBy: SortValue;
  showFilters: boolean;
  filterDefinition: FilterDefinition;
  viewModified: boolean;
  views: CrewMerchantUi.SearchView[];
  view: CrewMerchantUi.SearchView;
};

export type SearchControlAction =
  | {
      type: 'toggleFilters';
      show?: boolean;
    }
  | {
      type: 'filterChange';
      filter: Filter;
    }
  | {
      type: 'removeFilters';
      ids?: Filter['id'][];
    }
  | {
      type: 'sortChange';
      orderBy: SortValue;
    }
  | {
      type: 'viewChange';
      view: State['view'] | null;
    }
  | {
      // used to check for the initial state to avoid React error of updating component while rendering
      type: 'initialize';
      state: State;
    };

export const LOCAL_VIEW_ID = Number.MIN_SAFE_INTEGER;
const createLocalView = <Entity extends CrewMerchantUi.SearchView['kind']>(
  entity: Entity,
) =>
  ({
    // maybe better named as All view?
    id: LOCAL_VIEW_ID,
    name: 'Local View',
    kind: entity,
    filters: {},
  }) satisfies CrewMerchantUi.SearchView;

const isViewModified = (
  view: CrewMerchantUi.SearchView,
  views: CrewMerchantUi.SearchView[],
) => {
  if (view.id === LOCAL_VIEW_ID) {
    return Object.values(view.filters).some((value) => !!value);
  }

  const originalView = views.find((v) => v.id === view.id);
  if (!originalView) {
    return true;
  }

  return !isEqual(originalView.filters, view.filters);
};

const resetView = (view: State['view'], views: State['views']) => {
  if (view.id === LOCAL_VIEW_ID) {
    return createLocalView(view.kind);
  }

  const originalView = views.find((v) => v.id === view.id);

  return originalView ?? createLocalView(view.kind);
};

const handleAction = (state: State, action: SearchControlAction): State =>
  match<SearchControlAction, State>(action)
    .with({ type: 'toggleFilters' }, ({ show }) => {
      const showFilters = show ?? !state.showFilters;
      const view =
        showFilters ? state.view : resetView(state.view, state.views);

      return {
        ...state,
        view,
        viewModified: false,
        showFilters,
      };
    })
    .with({ type: 'filterChange' }, ({ filter: { id, value } }) => {
      const view = {
        ...state.view,
        filters: {
          ...state.view.filters,
          [id]: value,
        },
      } satisfies CrewMerchantUi.SearchView;

      return {
        ...state,
        view,
        viewModified: isViewModified(view, state.views),
      };
    })
    .with({ type: 'removeFilters' }, ({ ids }) => {
      const updatedFilters: CrewMerchantUi.SearchView['filters'] =
        // no ids provided, clear all filters
        ids ?
          {
            ...Object.fromEntries(
              Object.entries(state.view.filters).filter(
                ([id]) => !ids.includes(id as keyof typeof state.view.filters),
              ),
            ),
          }
        : {};

      const view = {
        ...state.view,
        filters: updatedFilters,
      } satisfies CrewMerchantUi.SearchView;

      return {
        ...state,
        view,
        viewModified: isViewModified(view, state.views),
      };
    })
    .with({ type: 'sortChange' }, ({ orderBy }) => ({
      ...state,
      orderBy,
    }))
    .with({ type: 'viewChange' }, ({ view }) => ({
      ...state,
      orderBy: { column: state.sortOptions[0]?.value ?? '', direction: 'desc' },
      view: view ?? createLocalView(state.kind),
      viewModified: false,
      showFilters: false,
    }))
    .with({ type: 'initialize' }, ({ state: s }) => s)
    .exhaustive();

export const useSearchControlBarState = <
  Entity extends
    CrewMerchantUi.SearchView['kind'] = CrewMerchantUi.SearchView['kind'],
>(
  entity: Entity,
  onStateChange: (state: State, action: SearchControlAction) => void,
) => {
  const storeId = useStoreId();
  const filterDefinition = useFilterDefinition(entity);
  const { data: views } = useSearchViews(entity);
  const sortOptions = useSortOptions();

  const initialState: State = {
    kind: entity,
    sortOptions,
    orderBy: { column: sortOptions[0]?.value ?? '', direction: 'desc' },
    showFilters: false,
    filterDefinition,
    viewModified: false,
    views: views ?? [],
    view: createLocalView(entity),
  };

  const [state, persistState] = useLocalStorage<State>(
    `${storeId}-${entity}-search`,
    initialState,
    {
      serializer: ({ view, orderBy, showFilters, viewModified }) =>
        JSON.stringify({ view, orderBy, showFilters, viewModified }),
      deserializer: (serialized) => {
        const parsed = stringToJSON()
          .pipe(
            z.object({
              view: CrewMerchantUi.searchViewSchema,
              orderBy: sortValueSchema,
              showFilters: z.boolean(),
              viewModified: z.boolean(),
            }),
          )
          .safeParse(serialized);

        const notValid =
          !parsed.success ||
          parsed.data.view.kind !== entity ||
          (parsed.data.view.id !== LOCAL_VIEW_ID &&
            !views?.find((v) => v.id === parsed.data.view.id));

        if (notValid) {
          // eslint-disable-next-line no-console
          console.warn(`Invalid ${entity} SearchControlBar State`, {
            parsed,
            error: parsed.error,
          });

          return initialState;
        }

        return {
          ...initialState,
          ...parsed.data,
          filterDefinition,
        };
      },
    },
  );

  const dispatch = useCallback(
    <Type extends SearchControlAction['type']>(
      action: Extract<SearchControlAction, { type: Type }>,
    ) => {
      const newState = handleAction(state, action);

      persistState(newState);

      onStateChange(newState, action);
    },
    [state, persistState, onStateChange],
  );

  useEffect(() => {
    onStateChange(state, { type: 'initialize', state });
    // eslint-disable-next-line react-hooks/exhaustive-deps --- just want to run once
  }, []);

  return [state, dispatch] as const;
};

const presetDurations = {
  [DatePreset.today]: {},
  [DatePreset.last7Days]: { days: 7 },
  [DatePreset.last30Days]: { days: 30 },
  [DatePreset.last90Days]: { days: 90 },
  [DatePreset.last12Months]: { months: 12 },
} as const satisfies Record<Exclude<DatePreset, DatePreset.custom>, Duration>;

const computeDateFilterValue = (
  value: z.infer<typeof CrewMerchantUi.searchViewDateSchema>,
) => {
  if (value.kind === 'preset') {
    return {
      start: startOfDay(
        sub(new Date(), presetDurations[value.preset]),
      ).toISOString(),
      end: null,
    };
  }

  const start =
    value.range?.start ?
      startOfDay(new Date(value.range.start)).toISOString()
    : null;
  const end =
    value.range?.end ? endOfDay(new Date(value.range.end)).toISOString() : null;

  return { start, end };
};

// TODO automation-like type safety for filters
// ! this allows us to lie in the types, because there's no type-enforced relationship between the filter and it's resulting type, anything additional properties can really be almost anything; i.e. any arbitrary key can be added with any value, and duck typing says it's fine
export const convertStateToSearch = (
  state: State,
): Omit<
  Extract<CrewMerchantUi.SearchReq, { kind: State['kind'] }>,
  'cursor' | 'take'
> => {
  const {
    view,
    filterDefinition: { filters },
  } = state;

  const filterIds = Object.keys(
    view.filters,
  ) as unknown as (keyof typeof view.filters)[];

  const searchFilters = filterIds.reduce((search, id) => {
    if (isNullish(view.filters[id])) return search;

    if (id !== 'searchTerm' && filters[id]?.type === FilterControlType.date) {
      return {
        ...search,
        //! TODO: gotta figure out to the view values related to the filter definition
        [id]: computeDateFilterValue(
          view.filters[id] as z.infer<
            typeof CrewMerchantUi.searchViewDateSchema
          >,
        ),
      };
    }

    return { ...search, [id]: view.filters[id] };
  }, {});

  return {
    orderBy: state.orderBy.direction,
    filters: searchFilters,
    kind: state.kind,
  };
};
