import {
  faArrowDownToBracket,
  faBarsFilter,
  faMagnifyingGlass,
} from '@fortawesome/pro-solid-svg-icons';
import {
  CrewClaimResolutionMethodEnum,
  CrewClaimRollupStatusCode,
  CrewMerchantApi,
  isEnum,
} from 'corso-types';
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebounceCallback } from 'usehooks-ts';
import RunReport from '~/components/claim/RunReport';
import { TextInput } from '~/components/field';
import Icon from '~/components/Icon';
import SpinnerIcon from '~/components/SpinnerIcon';
import { Action } from '~/components/ui/Action';
import { Button } from '~/components/ui/primitives/Button';
import Filters from './Filters';
import SortMenu from './SortMenu';
import {
  convertStateToSearch,
  LOCAL_VIEW_ID,
  SearchControlAction,
  State,
  useSearchControlBarState,
} from './useSearchControlBarState';
import { useUpdateSearchView } from './useSearchViewApi';
import { CreateView, Views } from './Views';

type SearchReq = Omit<
  CrewMerchantApi.RequestBody<'/:storeId/search', 'post'>,
  'cursor' | 'take'
>;

type SearchControlBarProps<Entity extends SearchReq['kind']> = {
  entity: Entity;
  onSearchChange: (search: SearchReq) => void;
  isSearchPending?: boolean;
  className?: string;
};

export type SearchControlBarRef = {
  clearFilters: () => void;
};

function TopBarSpinner({ show }: { show?: boolean }) {
  return (
    <SpinnerIcon
      className={twMerge(
        'invisible size-5 text-corso-gray-600',
        show && 'visible',
      )}
    />
  );
}

function ViewTopBar({
  state,
  dispatch,
  isSearchPending,
}: {
  state: State;
  dispatch: (action: SearchControlAction) => void;
  isSearchPending?: boolean;
}) {
  return (
    <div className="flex flex-grow items-center gap-2">
      <Views
        entity={state.kind}
        selectedView={state.view}
        onViewEdit={() => dispatch({ type: 'toggleFilters', show: true })}
        onViewSelect={(view) => dispatch({ type: 'viewChange', view })}
      />

      <TopBarSpinner show={isSearchPending} />

      <Button
        className="min-h-8 text-xs"
        onClick={() => dispatch({ type: 'toggleFilters', show: true })}
        data-testid="toggle"
      >
        <div className="flex items-center gap-1">
          <span className="sr-only">Search and filter</span>
          <Icon icon={faMagnifyingGlass} size="sm" />
          <Icon icon={faBarsFilter} size="sm" />
        </div>
      </Button>
    </div>
  );
}

function FilterTopBar({
  state,
  dispatch,
  isSearchPending,
}: {
  state: State;
  dispatch: (action: SearchControlAction) => void;
  isSearchPending?: boolean;
}) {
  const { mutate: saveChanges } = useUpdateSearchView((updatedView) => {
    dispatch({ type: 'viewChange', view: updatedView });
  });

  const { view: selectedView } = state;

  const { searchTerm } = state.filterDefinition.filters;

  return (
    <div className="flex flex-grow items-center gap-2">
      <TextInput
        id="search"
        label="Search"
        className="flex-grow"
        onChange={(e) =>
          dispatch({
            type: 'filterChange',
            filter: { ...searchTerm, value: e.target.value },
          })
        }
        value={state.view.filters.searchTerm ?? ''}
        labelVisuallyHidden
        placeholder={
          selectedView.id === LOCAL_VIEW_ID ?
            'Search all'
          : `Searching in ${selectedView.name}`
        }
        addon={{
          insideStart: <Icon icon={faMagnifyingGlass} className="size-3" />,
        }}
      />
      <TopBarSpinner show={isSearchPending} />

      <Button
        className="min-h-8 text-xs"
        onClick={() => dispatch({ type: 'toggleFilters', show: false })}
        data-testid="toggle"
      >
        Cancel
      </Button>
      {state.view.id === LOCAL_VIEW_ID ?
        <CreateView
          view={{ kind: state.kind, filters: state.view.filters }}
          onCreate={(view) => dispatch({ type: 'viewChange', view })}
          triggerContent="Save as"
        />
      : <Button
          className="min-h-8 text-xs"
          disabled={!state.viewModified}
          onClick={() => {
            saveChanges(state.view);
          }}
        >
          Save
        </Button>
      }
    </div>
  );
}

function SearchControlBar<Entity extends SearchReq['kind']>(
  {
    entity,
    onSearchChange,
    isSearchPending,
    className,
  }: SearchControlBarProps<Entity>,
  ref: ForwardedRef<SearchControlBarRef>,
) {
  const debouncedOnSearchChange = useDebounceCallback(onSearchChange, 300);

  const onStateChange = (state: State, action: SearchControlAction) => {
    // if just showing filters, don't trigger a search
    if (action.type === 'toggleFilters' && state.showFilters) {
      return;
    }

    const search = convertStateToSearch(state);
    // it seems like if onSearchChange is called when initializing it will cause an error
    const needToDebounce =
      (action.type === 'filterChange' && action.filter.id === 'searchTerm') ||
      action.type === 'initialize';

    if (needToDebounce) {
      debouncedOnSearchChange(search);
    } else {
      onSearchChange(search);
    }
  };

  const [state, dispatch] = useSearchControlBarState(entity, onStateChange);
  const [showExport, setShowExport] = useState(false);

  useImperativeHandle(ref, () => ({
    clearFilters: () => {
      dispatch({ type: 'removeFilters' });
    },
  }));

  const allowExport = entity === 'Return' || entity === 'Warranty';
  const search = convertStateToSearch(state);

  // filter out the search term from the filters since it's handled separately
  const { searchTerm, ...activeFilters } = state.view.filters;
  const { searchTerm: searchDefinition, ...filters } =
    state.filterDefinition.filters;

  return (
    <div
      className={twMerge(
        'w-full divide-y divide-corso-gray-200 bg-white',
        className,
      )}
    >
      <div className="flex items-center gap-2 p-2">
        {state.showFilters ?
          <FilterTopBar
            state={state}
            dispatch={dispatch}
            isSearchPending={isSearchPending}
          />
        : <ViewTopBar
            state={state}
            dispatch={dispatch}
            isSearchPending={isSearchPending}
          />
        }

        <SortMenu
          options={state.sortOptions}
          value={state.orderBy}
          onChange={(orderBy) => dispatch({ type: 'sortChange', orderBy })}
        />
        {allowExport && (
          <Action
            className="min-h-8"
            icon={faArrowDownToBracket}
            onClick={() => setShowExport(true)}
            disabled={isSearchPending}
            accessibilityLabel="Export"
            iconSize="sm"
          />
        )}
      </div>

      {state.showFilters && (
        <Filters
          className={twMerge(
            'p-2',
            state.showFilters ?
              'animate-in fade-in-25'
            : 'animate-out fade-out-25',
          )}
          filters={filters}
          activeFilters={activeFilters}
          onChange={(change) =>
            dispatch({
              type: 'filterChange',
              filter: change,
            })
          }
          onRemoveFilters={(ids) => dispatch({ type: 'removeFilters', ids })}
        />
      )}

      {/* // TODO adjust how search is evaluated to help assert the types */}
      <RunReport
        searchTerm={search.filters.searchTerm}
        orderBy={search.orderBy}
        show={showExport}
        claimType="Return"
        createdOn={search.filters.createdOn}
        statusCodes={
          (
            'statusCodes' in search &&
            Array.isArray(search.statusCodes) &&
            search.statusCodes.length
          ) ?
            search.statusCodes.filter((status) =>
              isEnum(status, CrewClaimRollupStatusCode),
            )
          : undefined
        }
        resolutionMethods={
          (
            'requestedResolutionMethods' in search &&
            Array.isArray(search.requestedResolutionMethods) &&
            search.requestedResolutionMethods.length
          ) ?
            search.requestedResolutionMethods.filter((method) =>
              isEnum(method, CrewClaimResolutionMethodEnum),
            )
          : undefined
        }
        onClose={() => setShowExport(false)}
      />
    </div>
  );
}

export default forwardRef(SearchControlBar);
