import {
  faArrowDown,
  faArrowUp,
  faArrowUpArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import Icon from '~/components/Icon';
import { Action } from '~/components/ui/Action';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/primitives/DropdownMenu';

type SortDirection = 'asc' | 'desc';
export type SortMenuOption = {
  label: string;
  value: string;
  sortLabels?: Record<SortDirection, string>;
};

type SortMenuProps = {
  options: SortMenuOption[];
  value: { column: string; direction: 'asc' | 'desc' };
  onChange: (value: { column: string; direction: 'asc' | 'desc' }) => void;
};

export default function SortMenu({ options, value, onChange }: SortMenuProps) {
  const sortLabels = options.find((option) => option.value === value.column)
    ?.sortLabels ?? {
    asc: 'Oldest to newest',
    desc: 'Newest to oldest',
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Action
          className="min-h-8"
          icon={faArrowUpArrowDown}
          accessibilityLabel="Sort"
          iconSize="sm"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel className="text-xs">Sort by</DropdownMenuLabel>
        <DropdownMenuRadioGroup
          value={value.column}
          onValueChange={(column) =>
            onChange({ column, direction: value.direction })
          }
        >
          {options.map((option) => (
            <DropdownMenuRadioItem
              key={option.value}
              value={option.value}
              className="text-xs"
              onSelect={(event) => event.preventDefault()}
            >
              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />

        <DropdownMenuItem
          className={twMerge(
            'text-xs',
            value.direction === 'asc' && 'bg-corso-gray-200',
          )}
          onSelect={(event) => {
            event.preventDefault();
            onChange({ column: value.column, direction: 'asc' });
          }}
        >
          <Icon icon={faArrowUp} className="mr-2 size-4" />
          {sortLabels?.asc}
        </DropdownMenuItem>
        <DropdownMenuItem
          className={twMerge(
            'text-xs',
            value.direction === 'desc' && 'bg-corso-gray-200',
          )}
          onSelect={(event) => {
            event.preventDefault();
            onChange({ column: value.column, direction: 'desc' });
          }}
        >
          <Icon icon={faArrowDown} className="mr-2 size-4" />
          {sortLabels?.desc}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
