import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { CrewMerchantUi, isNullish } from 'corso-types';
import { ForwardedRef, forwardRef, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Action } from '~/components/ui/Action';
import { Button } from '~/components/ui/primitives/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/primitives/DropdownMenu';
import FilterControl from './Filter';
import { Filter, FilterDefinition } from './useFilterDefinition';

type FilterId = Exclude<keyof FilterDefinition['filters'], 'searchTerm'>;

type AddFilterMenuProps = {
  options: { id: FilterId; label: string }[];
  onSelect: (id: FilterId) => void;
};

function AddFilterMenu({ options, onSelect }: AddFilterMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Action className="min-h-8 text-xs" icon={faPlus} side="right">
          Add filter
        </Action>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {options.map(({ id, label }) => (
          <DropdownMenuItem
            key={id}
            onSelect={() => onSelect(id)}
            className="text-xs"
          >
            {label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

type FiltersProps = {
  filters: Omit<FilterDefinition['filters'], 'searchTerm'>;
  activeFilters: Omit<CrewMerchantUi.SearchView['filters'], 'searchTerm'>;
  onChange: (changed: Filter) => void;
  onRemoveFilters: (id?: (keyof FilterDefinition['filters'])[]) => void;
  className?: string;
};

function Filters(
  {
    filters,
    activeFilters,
    className,
    onChange,
    onRemoveFilters,
  }: FiltersProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [newFilterId, setNewFilterId] = useState<
    keyof FiltersProps['filters'] | null
  >(null);

  const notActiveOptions = useMemo(
    () =>
      Object.values(filters)
        .filter((filter) => isNullish(activeFilters[filter.id]))
        .map(
          (filter) =>
            ({ id: filter.id, label: filter.label }) satisfies {
              id: FilterId;
              label: string;
            },
        ),
    [filters, activeFilters],
  );

  const handleChange = (changed: Filter) => {
    if (changed.id === newFilterId) {
      setNewFilterId(null);
    }
    onChange(changed);
  };

  const handleRemove = (id: keyof FiltersProps['filters']) => {
    onRemoveFilters([id]);
  };

  const handleNewFilter = (id: keyof FiltersProps['filters']) => {
    const filter = filters[id];
    if (filter) {
      setNewFilterId(filter.id);
    }
  };

  const handleClear = () => {
    onRemoveFilters(
      Object.keys(activeFilters) as (keyof FiltersProps['activeFilters'])[],
    );
  };

  return (
    <div
      data-testid="filters"
      className={twMerge(
        'flex flex-wrap items-center gap-2 overflow-x-auto',
        className,
      )}
      ref={ref}
    >
      {(
        Object.keys(activeFilters) as (keyof FiltersProps['activeFilters'])[]
      ).map((id) => (
        <FilterControl
          key={id}
          filter={{ ...filters[id], value: activeFilters[id] }}
          onChange={handleChange}
          onRemove={() => handleRemove(id)}
        />
      ))}
      {newFilterId && (
        <FilterControl
          defaultOpen
          filter={filters[newFilterId]}
          onChange={handleChange}
          onRemove={() => setNewFilterId(null)}
        />
      )}

      {notActiveOptions.length > 0 && (
        <AddFilterMenu options={notActiveOptions} onSelect={handleNewFilter} />
      )}
      {Object.keys(activeFilters).length > 0 && (
        <Button className="min-h-8 text-xs" onClick={handleClear}>
          Clear all
        </Button>
      )}
    </div>
  );
}

export default forwardRef(Filters);
