import {
  DocumentArrowDownIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { isTruthy } from 'corso-types';
import api from '~/api';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import Menu from '~/components/Menu';
import Page from '~/components/Page';
import { Action } from '~/components/ui/Action';
import { useStoreId } from '~/hooks/useStoreId';
import { formatter } from '~/utils/formatter';

const useQueryKey = () => {
  const storeId = useStoreId();
  return ['invoices', storeId] as const;
};

const useInvoices = () => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) => api.store(storeId).invoices.list(),
    select: (data) =>
      data.map((invoice) => ({
        ...invoice,
        hasDownloads: [
          invoice.ordersCsvUrl,
          invoice.refundsCsvUrl,
          invoice.reordersCsvUrl,
          invoice.adjustmentsCsvUrl,
        ].some(isTruthy),
      })),
  });
};

export default function Invoices() {
  const { data: invoices = [], isLoading } = useInvoices();
  return (
    <Page title="Invoices">
      <Card>
        {invoices.length ?
          <ul className="divide-y divide-gray-100">
            {invoices.map((invoice) => (
              <li
                key={invoice.id}
                className="flex items-center justify-between gap-x-6 p-4 first:rounded-t-md last:rounded-b-md even:bg-gray-50 sm:px-6"
              >
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      Invoice #{invoice.id}
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="whitespace-nowrap">
                      Generated on{' '}
                      <time dateTime={invoice.createdOn}>
                        {formatter.date(invoice.createdOn)}
                      </time>
                    </p>
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle r={1} cx={1} cy={1} />
                    </svg>
                    <p className="truncate">
                      {formatter.date(new Date(invoice.fromDate))}&nbsp;-&nbsp;
                      {formatter.date(new Date(invoice.toDate))}
                    </p>
                  </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  {invoice.isMonthlyCrew && (
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {formatter.currency(invoice.totalAmount, 'USD')}
                    </p>
                  )}
                  {invoice.invoicePdfUrl && (
                    <Action
                      icon={DocumentArrowDownIcon}
                      accessibilityLabel="Download PDF"
                      to={invoice.invoicePdfUrl}
                    />
                  )}
                  {invoice.hasDownloads && (
                    <Menu
                      buttonAs={
                        <Action
                          icon={EllipsisHorizontalIcon}
                          accessibilityLabel="CSV Downloads"
                        />
                      }
                    >
                      {invoice.ordersCsvUrl && (
                        <Menu.ItemLink to={invoice.ordersCsvUrl}>
                          <DocumentArrowDownIcon
                            className="h-5 w-5"
                            aria-label="Download"
                          />
                          Orders
                        </Menu.ItemLink>
                      )}
                      {invoice.refundsCsvUrl && (
                        <Menu.ItemLink to={invoice.refundsCsvUrl}>
                          <DocumentArrowDownIcon
                            className="h-5 w-5"
                            aria-label="Download"
                          />
                          Refunds
                        </Menu.ItemLink>
                      )}
                      {invoice.reordersCsvUrl && (
                        <Menu.ItemLink to={invoice.reordersCsvUrl}>
                          <DocumentArrowDownIcon
                            className="h-5 w-5"
                            aria-label="Download"
                          />
                          Reorders
                        </Menu.ItemLink>
                      )}
                      {invoice.adjustmentsCsvUrl && (
                        <Menu.ItemLink to={invoice.adjustmentsCsvUrl}>
                          <DocumentArrowDownIcon
                            className="h-5 w-5"
                            aria-label="Download"
                          />
                          Adjustments
                        </Menu.ItemLink>
                      )}
                    </Menu>
                  )}
                </div>
              </li>
            ))}
          </ul>
        : <Alert
            title={isLoading ? 'Loading invoices...' : 'No Invoices Found'}
            message={
              isLoading ?
                'Please wait a moment.'
              : 'No invoices have been generated, please check back later.'
            }
          />
        }
      </Card>
    </Page>
  );
}
