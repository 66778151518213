import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { isNullish } from 'corso-types';
import { useState } from 'react';
import { match } from 'ts-pattern';
import ButtonGroup from '~/components/ButtonGroup';
import { FieldGroup } from '~/components/field/FieldGroup';
import { Action } from '~/components/ui/Action';
import { MultiSelectContent } from '~/components/ui/MultiSelect';
import { Button } from '~/components/ui/primitives/Button';
import { DatePicker } from '~/components/ui/primitives/DatePicker';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '~/components/ui/primitives/DropdownMenu';
import { Label } from '~/components/ui/primitives/Label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/primitives/Popover';
import { formatter } from '~/utils/formatter';
import {
  DatePreset,
  FilterControlType,
  Filter as FilterType,
  FilterTypeOptions,
} from './useFilterDefinition';

type ControlProps<F extends FilterType> = {
  filter: F;
  onChange: (filter: F) => void;
  onClose: () => void;
  defaultOpen?: boolean;
};

function ClearButton({
  onClick,
  disabled,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <Button
      disabled={disabled}
      variant="link"
      className="min-h-8 text-xs"
      onClick={onClick}
    >
      Clear
    </Button>
  );
}

const formatDateTriggerLabel = ({
  label,
  options,
  value,
}: {
  label: string;
  options: FilterTypeOptions[FilterControlType.date];
  value?: DateFilter['value'] | null;
}) => {
  if (!value) {
    return label;
  }

  if (value.kind === 'preset') {
    const selectedLabel = options.find(
      (option) => option.value === value.preset,
    )?.label;
    return `${label}${selectedLabel ? `: ${selectedLabel}` : ''}`;
  }

  const starting = value.range?.start ? formatter.date(value.range.start) : '';
  const ending = value.range?.end ? formatter.date(value.range.end) : '';

  if (starting && ending) {
    return `${label}: ${starting} - ${ending}`;
  }

  if (starting) {
    return `${label}: Starting ${starting}`;
  }

  if (ending) {
    return `${label}: Ending ${ending}`;
  }

  return label;
};

type DateFilter = Extract<FilterType, { type: FilterControlType.date }>;
function DateMenu<Type extends DateFilter>({
  filter,
  defaultOpen,
  onChange,
  onClose,
}: ControlProps<Type>) {
  const { label, options, value } = filter;
  const [selectedOption, setSelectedOption] = useState<`${DatePreset}` | null>(
    // eslint-disable-next-line no-nested-ternary
    value?.kind === 'preset' ? value.preset
    : value?.range ? 'custom'
    : null,
  );

  const handleClear = () => {
    setSelectedOption(null);
    onChange({ ...filter, value: null });
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  const handleValueChange = (
    change: DateFilter['options'][number]['value'],
  ) => {
    setSelectedOption(change);
    if (change !== DatePreset.custom) {
      onChange({ ...filter, value: { kind: 'preset', preset: change } });
    }
  };

  const handleDateChange = (key: 'start' | 'end') => (date?: Date) => {
    onChange({
      ...filter,
      value: {
        kind: 'custom',
        range: {
          ...(value?.kind === 'custom' ?
            value.range
          : { start: null, end: null }),
          [key]: date ? date.toISOString() : null,
        },
      },
    });
  };

  const triggerLabel = formatDateTriggerLabel({
    label,
    options,
    value:
      selectedOption === 'custom' ?
        {
          kind: 'custom',
          range:
            value?.kind === 'custom' ? value.range : { start: null, end: null },
        }
      : value,
  });

  return (
    <DropdownMenu defaultOpen={defaultOpen} onOpenChange={handleOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button className="min-h-8 text-xs">{triggerLabel}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {/* intentionally not using a scroll area, so that this always shows the entire content  */}
        <DropdownMenuRadioGroup
          value={selectedOption ?? undefined}
          onValueChange={(change) =>
            handleValueChange(change as DateFilter['options'][number]['value'])
          }
        >
          {options.map((option) => (
            <DropdownMenuRadioItem
              key={option.value}
              value={option.value}
              onSelect={(event) => event.preventDefault()}
            >
              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
        {selectedOption === DatePreset.custom && (
          <div className="space-y-3 p-2">
            <FieldGroup>
              <Label htmlFor="starting">Starting</Label>
              <DatePicker
                id="starting"
                value={
                  value?.kind === 'custom' && value.range.start ?
                    new Date(value.range.start)
                  : undefined
                }
                onChange={handleDateChange('start')}
                disabled={
                  value?.kind === 'custom' &&
                  !!value?.range?.end && { after: new Date(value.range.end) }
                }
              />
            </FieldGroup>
            <FieldGroup>
              <Label htmlFor="ending">Ending</Label>
              <DatePicker
                id="ending"
                value={
                  value?.kind === 'custom' && value.range.end ?
                    new Date(value.range.end)
                  : undefined
                }
                onChange={handleDateChange('end')}
                disabled={
                  value?.kind === 'custom' &&
                  !!value?.range?.start && {
                    before: new Date(value.range.start),
                  }
                }
              />
            </FieldGroup>
          </div>
        )}
        <div className="px-2 py-1">
          <ClearButton disabled={!value} onClick={handleClear} />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function MultiSelectPopover<
  Type extends Extract<FilterType, { type: FilterControlType.multiselect }>,
>({ filter, defaultOpen, onChange, onClose }: ControlProps<Type>) {
  const { label, options, value } = filter;
  const currentValues = value ?? [];
  const selectedLabels = currentValues
    .map(
      (selected) => options.find((option) => option.value === selected)?.label,
    )
    .join(', ');

  const triggerLabel = `${label}${selectedLabels ? `: ${selectedLabels}` : ''}`;

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  return (
    // * modal prop necessary to prevent flicker and instant close of popover, but why this works is unclear
    <Popover defaultOpen={defaultOpen} onOpenChange={handleOpenChange} modal>
      <PopoverTrigger asChild>
        <Button className="min-h-8 text-xs">{triggerLabel}</Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0">
        <MultiSelectContent
          options={options}
          value={options.filter((option) =>
            currentValues.includes(option.value),
          )}
          onChange={(v) =>
            onChange({
              ...filter,
              value: v.length ? v.map((o) => o.value) : null,
            })
          }
          clearable
        />
      </PopoverContent>
    </Popover>
  );
}

function BooleanMenu<
  Type extends Extract<FilterType, { type: FilterControlType.boolean }>,
>({ filter, defaultOpen, onChange, onClose }: ControlProps<Type>) {
  const handleClear = () => {
    onChange({ ...filter, value: null });
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  const selectedLabel = filter.options.find(
    (option) => option.value === filter.value,
  )?.label;
  const triggerLabel = `${filter.label}${selectedLabel ? `: ${selectedLabel}` : ''}`;

  return (
    <DropdownMenu defaultOpen={defaultOpen} onOpenChange={handleOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button className="min-h-8 text-xs">{triggerLabel}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuRadioGroup
          value={isNullish(filter.value) ? undefined : `${filter.value}`}
          onValueChange={(v) =>
            onChange({
              ...filter,
              value: isNullish(v) ? v : v === 'true',
            })
          }
        >
          {filter.options
            .map((o) => ({
              ...o,
              value: `${o.value}` as const,
            }))
            .map((option) => (
              <DropdownMenuRadioItem
                key={option.value}
                value={option.value}
                onSelect={(event) => event.preventDefault()}
              >
                {option.label}
              </DropdownMenuRadioItem>
            ))}
        </DropdownMenuRadioGroup>
        <div className="px-2 py-1">
          <ClearButton
            disabled={isNullish(filter.value)}
            onClick={handleClear}
          />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default function Filter({
  filter,
  onRemove,
  ...props
}: {
  filter: FilterType;
  onChange: (filter: FilterType) => void;
  defaultOpen?: boolean;
  onRemove: () => void;
}) {
  // removes the filter if it closes with no value
  const handleClose = () => {
    // need to check for null or undefined since boolean filters can be false
    if (isNullish(filter.value)) {
      onRemove();
    }
  };

  const controlProps = {
    ...props,
    onClose: handleClose,
  };

  return (
    <ButtonGroup horizontal>
      {match(filter)
        .with({ type: FilterControlType.multiselect }, (multiSelect) => (
          <MultiSelectPopover
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...controlProps}
            filter={multiSelect}
          />
        ))
        .with({ type: FilterControlType.date }, (date) => (
          <DateMenu
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...controlProps}
            filter={date}
          />
        ))
        .with({ type: FilterControlType.boolean }, (boolean) => (
          <BooleanMenu
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...controlProps}
            filter={boolean}
          />
        ))
        .otherwise(() => null)}

      <Action
        onClick={onRemove}
        icon={faXmark}
        accessibilityLabel={`Remove filter: ${filter.label}`}
      />
    </ButtonGroup>
  );
}
