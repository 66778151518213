import { useMerchantContext } from '~/providers/MerchantProvider';

export function useShopifyPlan() {
  const {
    storeUser: {
      store: { shopifyPlan },
    },
  } = useMerchantContext();

  // ? do we default to basic here.. not sure?
  return shopifyPlan ?? 'Basic';
}

// Note to future us: We could probably persist Extensibility from Shopify instead of this
// This is our best guess at what plans have it
export function useHasCheckoutFunctionality() {
  const shopifyPlan = useShopifyPlan();

  return (
    shopifyPlan === 'Plus Trial' ||
    shopifyPlan === 'Shopify Plus' ||
    shopifyPlan === 'Shopify Plus Partner Sandbox' ||
    shopifyPlan === 'Developer Preview' ||
    shopifyPlan === 'Development'
  );
}
