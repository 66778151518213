import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ClaimResolutionMethodEnum,
  CrewMerchantUi,
  isTruthy,
  toEnum,
} from 'corso-types';
import { formatRelative } from 'date-fns';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import api from '~/api';
import Address from '~/components/Address';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import ExchangeOrderLineItem from '~/components/claim/resolutionLineItems/ExchangeOrderLineItem';
import RefundLineItem from '~/components/claim/resolutionLineItems/RefundLineItem';
import ClipboardButton from '~/components/ClipboardButton';
import DescriptionList from '~/components/DescriptionList';
import Feed from '~/components/Feed';
import LineItem from '~/components/LineItem';
import { Loading } from '~/components/Loading';
import MediaGallery from '~/components/MediaGallery';
import Modal from '~/components/Modal';
import Page from '~/components/Page';
import PageStatus from '~/components/PageStatus';
import RelativeDateTime from '~/components/RelativeDateTime';
import { Action } from '~/components/ui/Action';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { useStoreId } from '~/hooks/useStoreId';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  shippingClaimReasonName,
  shippingClaimResolutionMethodName,
  shippingClaimSourceName,
  shippingClaimStatusName,
} from '~/utils/enumNameMaps';
import { formatter } from '~/utils/formatter';

// todo: consolidate the feed components
// todo: fix the mobile UI view
// todo: add error handling on the mutations and queries

function FinalizeShippingClaim({
  resolutionMethod,
  show,
  onClose,
}: {
  resolutionMethod: ClaimResolutionMethodEnum;
  show: boolean;
  onClose: () => void;
}) {
  const { shippingClaimId } = useParams();
  const storeId = useStoreId();

  const { formState, control, getValues } = useForm({
    resolver: zodResolver(
      z.object({
        resolutionMethod: z.nativeEnum(ClaimResolutionMethodEnum),
        // ? include shippingClaimId, storeId
      }),
    ),
    defaultValues: {
      resolutionMethod,
    },
  });

  const queryClient = useQueryClient();

  const { mutate: finalizeClaim, isPending } = useMutation({
    mutationKey: ['shippingClaimFinalize', { shippingClaimId, storeId }],
    mutationFn: (selectedResolutionMethod: ClaimResolutionMethodEnum) => {
      if (!shippingClaimId) throw new Error('Missing Claim ID');
      return api
        .store(storeId)
        .shippingProtection.claim(shippingClaimId)
        .finalize(selectedResolutionMethod);
    },
    onSettled: () => {
      onClose();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['shippingClaim', { shippingClaimId, storeId }],
        data,
      );
      // * invalidate the claims query to update the claims list
      return queryClient.invalidateQueries({ queryKey: ['shippingClaims'] });
    },
  });

  const onSubmit = () => finalizeClaim(getValues('resolutionMethod'));

  return (
    <Modal
      show={show}
      title="Finalize Claim"
      onClose={onClose}
      actions={
        <>
          <Action onClick={onClose}>Cancel</Action>
          <Action
            variant="primary"
            onClick={onSubmit}
            loading={formState.isSubmitting || isPending}
          >
            Finalize Claim
          </Action>
        </>
      }
    >
      <form className="flex flex-col gap-4">
        <Controller
          control={control}
          name="resolutionMethod"
          render={({ field, fieldState }) => (
            <SimpleSelect
              label="Resolution Method"
              onChange={field.onChange}
              value={field.value}
              error={fieldState.error?.message}
              options={Object.values(ClaimResolutionMethodEnum).map(
                (value) => ({
                  value,
                  label: shippingClaimResolutionMethodName[value],
                }),
              )}
            />
          )}
        />
      </form>
    </Modal>
  );
}

function CorsoIcon() {
  // a real version of this SVG should be exported through Adobe Illustrator to optimize the `viewBox`
  return (
    <svg
      className="h-full"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      fill="currentColor"
      viewBox="0 0 200 234.59"
    >
      <g>
        <path d="M0,117.3c0,58.36,42.95,106.67,98.96,115.09v-62.37c-22.13-7.31-38.12-28.13-38.12-52.72s15.99-45.4,38.12-52.72V2.21 C42.95,10.62,0,58.94,0,117.3z" />
        {/* maybe could be simplified as a `circle` */}
        <path d="M161.04,76.44c17.83,0,32.29-14.46,32.29-32.29c0-17.83-14.46-32.29-32.29-32.29s-32.29,14.46-32.29,32.29 C128.75,61.98,143.2,76.44,161.04,76.44z" />
        <circle cx="161.04" cy="190.45" r="32.29" />
      </g>
    </svg>
  );
}

function ShippingClaimRequest({
  claim,
}: {
  claim: CrewMerchantUi.ShippingClaim;
}) {
  const {
    resolutionMethod,
    shippingClaimLineItems,
    noteFromCustomer,
    reason,
    source,
    images,
    originalStoreOrder: { orderNo },
  } = claim;

  const descriptions = [
    {
      term: 'Resolution Method',
      details: shippingClaimResolutionMethodName[resolutionMethod],
    },
    {
      term: 'Reason',
      details: shippingClaimReasonName[reason],
    },
    {
      term: 'Source',
      details: source ? shippingClaimSourceName[source] : 'Customer Portal',
    },
    ...(noteFromCustomer ? [{ term: 'Note', details: noteFromCustomer }] : []),
  ];

  return (
    <Card>
      <Card.Heading>
        {resolutionMethod} Requested By Customer For Order {orderNo}
      </Card.Heading>

      <DescriptionList descriptions={descriptions} />
      {shippingClaimLineItems.map((li) => (
        <LineItem
          variant="small"
          key={li.id}
          imageUrl={li.originalStoreOrderLineItem.imgUrl}
          sku={li.originalStoreOrderLineItem.sku}
          name={li.originalStoreOrderLineItem.name}
          quantity={li.quantity}
          options={li.originalStoreOrderLineItem.optionsFromPlatform}
        />
      ))}

      {images && images.length > 0 && (
        <>
          <Card.Heading>Customer Upload Media</Card.Heading>
          <MediaGallery
            media={images.map((url) => ({
              src: url,
              alt: 'Customer Uploaded Media',
            }))}
          />
        </>
      )}
    </Card>
  );
}

function ShippingClaimCustomerDetail({
  claim,
}: {
  claim: CrewMerchantUi.ShippingClaim;
}) {
  const { customerEmail, customerName, shippingAddress } = claim;

  const showContactInfo = !!customerEmail || !!shippingAddress?.phone;

  return (
    <Card>
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <Card.Heading>Customer</Card.Heading>
        </div>
        <p className="text-xs text-corso-gray-500">{customerName}</p>
      </div>

      {showContactInfo && (
        <div className="flex flex-col gap-1">
          <Card.Heading>Contact information</Card.Heading>
          <div className="flex items-center justify-between">
            <p className="text-xs text-corso-gray-500">{customerEmail}</p>

            <ClipboardButton
              variant="ghost"
              onClick={() => customerEmail ?? ''}
            />
          </div>
          <p className="text-xs text-corso-gray-500">
            {shippingAddress?.phone ?? 'No phone'}
          </p>
        </div>
      )}

      {shippingAddress && (
        <div className="flex flex-col gap-1">
          <Card.Heading>Shipping address</Card.Heading>
          <Address address={shippingAddress} />
        </div>
      )}
    </Card>
  );
}

function ShippingClaimAlert({
  showCorsoManagedAlert,
  someFinalized,
}: {
  showCorsoManagedAlert: boolean;
  someFinalized: boolean;
}) {
  // if the claim is being handled by the Corso Concierge Team, always show the alert
  if (showCorsoManagedAlert) {
    return (
      <Alert
        variant="DEFAULT"
        message={`This claim ${
          someFinalized ? 'was' : 'is currently being'
        } handled by the Corso Concierge Team.`}
      />
    );
  }

  // if the claim is handled by the merchant and has not been finalized, show the alert
  if (!someFinalized) {
    return (
      <Alert
        variant="DEFAULT"
        message="This claim has not been finalized yet."
      />
    );
  }

  return null;
}

function ShippingClaimResolutionSummary({
  claim,
}: {
  claim: CrewMerchantUi.ShippingClaim;
}) {
  const { data: spSettings } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const { resolutionSummary } = claim;

  const showCorsoManagedAlert = !!spSettings?.isMerchantFinalizationEnabled;

  const someFinalized = !!(
    resolutionSummary?.exchangeOrder ?? resolutionSummary?.refund
  );

  return (
    <Card>
      <Card.Heading>Resolution Summary</Card.Heading>

      <ShippingClaimAlert
        showCorsoManagedAlert={showCorsoManagedAlert}
        someFinalized={someFinalized}
      />

      {resolutionSummary?.exchangeOrder && (
        <div className="flex flex-col gap-4 divide-y rounded-md border p-4 shadow-sm">
          <ExchangeOrderLineItem
            lineItems={resolutionSummary.exchangeOrder.lineItems.map(
              (lineItem) => ({
                key: lineItem.id,
                name: lineItem.name,
                imageUrl: lineItem.imgUrl,
                price: lineItem.unitPrice,
                quantity: lineItem.quantity,
                sku: lineItem.sku,
                options: lineItem.optionsFromPlatform,
              }),
            )}
            replacementOrder={{
              adminLink: resolutionSummary.exchangeOrder.platformAdminUrl,
              nameFromPlatform:
                resolutionSummary.exchangeOrder.orderNameFromPlatform,
            }}
          />
        </div>
      )}

      {resolutionSummary?.refund && (
        <div className="flex flex-col gap-4 rounded-md border p-4 shadow-sm">
          <RefundLineItem amount={resolutionSummary.refund.amount} />
        </div>
      )}
    </Card>
  );
}

function ContentIFrame({ title, srcDoc }: { title: string; srcDoc: string }) {
  const [height, setHeight] = useState<number>();
  return (
    <iframe
      style={{
        '--iframe-height': height ? `${height}px` : 'auto',
      }}
      onLoad={(e) =>
        setHeight(e.currentTarget.contentDocument?.body.scrollHeight)
      }
      // height + 1% to try and avoid scrolling if possible
      className="h-[calc(var(--iframe-height)*1.01)] min-h-[--iframe-height] w-full"
      title={title}
      srcDoc={srcDoc}
    />
  );
}

function ShippingClaimMessages({
  claim: shippingClaim,
}: {
  claim: CrewMerchantUi.ShippingClaim;
}) {
  const { emailConversation: data } = shippingClaim;
  if (!data) return null;

  const messages = data.messages.map((message) => ({
    id: message.createdOn,
    iconSize: '32px',
    kind: 'message' as const,
    from: message.from,
    createdOn: message.createdOn,
    messageBody: message.sanitizedHtmlBody,
  }));

  return (
    <Card>
      <Card.Heading>Concierge Messages</Card.Heading>
      <div className="space-y-2 rounded-md border border-corso-gray-200 p-3 shadow-sm lg:p-4">
        <div className="space-y-4">
          <dl className="grid grid-cols-[auto_1fr] gap-2 text-xs">
            <dt className="font-semibold">Subject</dt>
            <dd className="break-words">{data.subject}</dd>
            <dt className="font-semibold">Customer</dt>
            <dd className="break-words">
              {data.customerName ?
                <>
                  {data.customerName}{' '}
                  <a
                    href={`mailto:${data.customerEmail}`}
                    className="text-right text-gray-500 hover:underline"
                  >
                    {data.customerEmail}
                  </a>
                </>
              : <a
                  href={`mailto:${data.customerEmail}`}
                  className="text-right text-gray-500 hover:underline"
                >
                  {data.customerEmail}
                </a>
              }
            </dd>
          </dl>
          {/* // ? maybe add icon distinguishing `from` */}
          <Feed
            events={messages}
            renderIcon={(message) => {
              if (message.from === data.customerEmail)
                return (
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-white font-bold text-gray-500">
                    {(data.customerName ?? data.customerEmail).charAt(0)}
                  </div>
                );
              return (
                <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-white p-2 text-gray-500">
                  <CorsoIcon />
                </div>
              );
            }}
          >
            {(message) => {
              const customerNameOrEmail =
                message.from === data.customerEmail && data.customerName ?
                  data.customerName
                : message.from;

              const title = `Message from ${customerNameOrEmail} on ${formatter.date(
                message.createdOn,
              )}`;

              return (
                <div className="w-full">
                  <div className="flex justify-between rounded-t-md border p-2 text-xs">
                    <span className="font-bold">{customerNameOrEmail}</span>
                    <time
                      dateTime={message.createdOn}
                      className="text-gray-500"
                    >
                      {formatRelative(new Date(message.createdOn), new Date())}
                    </time>
                  </div>
                  <div className="rounded-b-md border-x border-b">
                    <ContentIFrame
                      title={title}
                      srcDoc={`<!DOCTYPE html><html><head><title>${title}</title><style>body{background:white;padding:1rem;font-family:Arial;font-size:100%;}</style></head><body>${message.messageBody}</body></html>`}
                    />
                  </div>
                </div>
              );
            }}
          </Feed>
        </div>
      </div>
    </Card>
  );
}

/** Orchestrates all the claim components together for rendering within the context. */
export default function ShippingClaimOverview() {
  const { shippingClaimId } = useParams();
  const storeId = useStoreId();
  const { storeUser } = useMerchantContext();

  const { data: shippingProtectionSettings } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const {
    data: shippingClaim,
    isPending,
    isError,
    error,
  } = useQuery({
    enabled: !!shippingClaimId,
    queryKey: ['shippingClaim', { shippingClaimId, storeId }],
    queryFn: () => {
      if (!shippingClaimId) throw new Error('Missing Claim ID');
      return api.store(storeId).shippingProtection.claim(shippingClaimId).get();
    },
  });

  const [showFinalizeModal, setShowFinalizeModal] = useState(false);

  if (isPending) return <Loading />;

  if (isError) {
    // TODO differentiate client and server errors, and only propagate server/request errors
    return <PageStatus.Error error={error} />;
  }

  const canFinalize = !!(
    shippingProtectionSettings?.isMerchantFinalizationEnabled &&
    !shippingClaim.resolvedOn
  );

  return (
    <Page
      backAction
      title={
        shippingClaim.linkToCustomerUi ?
          {
            content: `#${shippingClaim.id}`,
            to: shippingClaim.linkToCustomerUi,
            target: '_blank',
          }
        : `#${shippingClaim.id}`
      }
      subtitle={
        <>
          <p>{shippingClaimStatusName[shippingClaim.status]}</p>
          <p>
            Created <RelativeDateTime dateTime={shippingClaim.createdOn} full />
          </p>
          {shippingClaim.resolvedOn && shippingClaim.resolvedIn && (
            <p>
              Finalized{' '}
              <RelativeDateTime dateTime={shippingClaim.resolvedOn} full /> by{' '}
              {shippingClaim.resolvedIn === 'Merchant_App' ?
                `${storeUser.store.name} Team`
              : 'Corso Team'}
            </p>
          )}
        </>
      }
      primaryAction={
        canFinalize ?
          {
            content: 'Finalize Claim',
            onAction: () => setShowFinalizeModal(true),
          }
        : undefined
      }
      secondaryActions={[
        shippingClaim.originalStoreOrder.platformAdminUrl && {
          id: 'view-order',
          icon: ArrowTopRightOnSquareIcon,
          content:
            shippingClaim.originalStoreOrder.orderNo ?
              `View Order ${shippingClaim.originalStoreOrder.orderNo}`
            : 'View Order',
          to: shippingClaim.originalStoreOrder.platformAdminUrl,
          target: '_blank',
        },
      ].filter(isTruthy)}
    >
      <FinalizeShippingClaim
        resolutionMethod={toEnum(
          shippingClaim.resolutionMethod,
          ClaimResolutionMethodEnum,
        )}
        show={showFinalizeModal}
        onClose={() => setShowFinalizeModal(false)}
      />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-[3fr_1fr]">
        <div className="space-y-4">
          <ShippingClaimResolutionSummary claim={shippingClaim} />
          <ShippingClaimRequest claim={shippingClaim} />
          <ShippingClaimMessages claim={shippingClaim} />
        </div>
        <div className="order-first space-y-4 md:order-none">
          <ShippingClaimCustomerDetail claim={shippingClaim} />
        </div>
      </div>
    </Page>
  );
}
