import { faIcons, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { PlayIcon } from '@heroicons/react/20/solid';
import { isTruthy } from 'corso-types';
import {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import ButtonGroup from '~/components/ButtonGroup';
import Card from '~/components/Card';
import Disclosure from '~/components/Disclosure';
import Icon from '~/components/Icon';
import Page from '~/components/Page';
import SpinnerIcon from '~/components/SpinnerIcon';
import { Action } from '~/components/ui/Action';
import { Button } from '~/components/ui/primitives/Button';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { cn } from '~/utils/shadcn';

function SwatchSet({
  heading,
  description,
  children,
}: PropsWithChildren<{ heading: ReactNode; description: ReactNode }>) {
  return (
    <Disclosure
      renderSummary={(open) => (
        <Card>
          <div className="flex items-center gap-1">
            <PlayIcon
              className={cn(
                'block size-3 rotate-0 text-corso-gray-500 transition-transform duration-75 ease-out',
                open && 'rotate-90',
              )}
              aria-hidden="true"
            />
            {heading}
          </div>

          {description}
        </Card>
      )}
    >
      <div className="flex flex-col gap-4 py-4">{children}</div>
    </Disclosure>
  );
}

function ButtonVariants() {
  const buttonVariants = [
    'default',
    'ghost',
    'primary',
    'link',
    'destructive',
    'caution',
  ] as const;
  const buttonSizes = ['default', 'lg'] as const;

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
      {buttonVariants.map((variant) => (
        <Card key={variant}>
          <Card.Heading>
            <code>{variant}</code> Button
          </Card.Heading>
          <Card>
            <Card.Heading>Intrinsic Side-by-Side Size Comparison</Card.Heading>
            <div className="flex items-end gap-2">
              {buttonSizes.map((size) => (
                <Fragment key={size}>
                  <Button variant={variant} size={size}>
                    {variant} {size === 'default' ? '' : size}
                  </Button>
                  <Button variant={variant} size={size} icon>
                    <Icon icon={faIcons} />
                  </Button>
                </Fragment>
              ))}
            </div>
            <div className="flex items-end gap-2">
              {buttonSizes.map((size) => (
                <Fragment key={size}>
                  <Button variant={variant} size={size} pressed>
                    {variant} {size === 'default' ? '' : size} pressed
                  </Button>
                  <Button variant={variant} size={size} pressed icon>
                    <Icon icon={faIcons} />
                  </Button>
                </Fragment>
              ))}
            </div>
            <div className="flex items-end gap-2">
              {buttonSizes.map((size) => (
                <Fragment key={size}>
                  <Button variant={variant} size={size} disabled>
                    {variant} {size === 'default' ? '' : size} disabled
                  </Button>
                  <Button variant={variant} size={size} disabled icon>
                    <Icon icon={faIcons} />
                  </Button>
                </Fragment>
              ))}
            </div>
          </Card>
          <Card>
            <Card.Heading>Full Width</Card.Heading>
            <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
              {buttonSizes.map((size) => (
                <Fragment key={size}>
                  <Button variant={variant} size={size}>
                    {variant} {size === 'default' ? '' : size}
                  </Button>
                  <Button variant={variant} size={size} disabled>
                    {variant} disabled
                  </Button>
                  <Button variant={variant} size={size} pressed disabled>
                    {variant} pressed disabled
                  </Button>
                </Fragment>
              ))}
            </div>
          </Card>
        </Card>
      ))}
    </div>
  );
}

function ButtonUseCases() {
  const options = ['A', 'B', 'C'] as const;
  const [selected, setSelected] = useState<(typeof options)[number]>('A');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    if (!loading) clearTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [loading]);

  return (
    <Card>
      <Card.Heading>Button Use Cases</Card.Heading>
      <Card.Description>
        Buttons can be used in a variety of ways; however, many of these use
        cases are better defined by the <code>Action</code> component.
      </Card.Description>
      <div className="flex flex-wrap gap-4">
        <div className="flex-grow">
          <Card>
            <Card.Heading>Button Group</Card.Heading>
            <ButtonGroup>
              {options.map((option) => (
                <Button
                  key={option}
                  pressed={option === selected}
                  onClick={() => setSelected(option)}
                >
                  {option}
                </Button>
              ))}
            </ButtonGroup>
            <ButtonGroup>
              {options.map((option) => (
                <Button
                  key={option}
                  variant={option === selected ? 'primary' : 'default'}
                  onClick={() => setSelected(option)}
                >
                  {option}
                </Button>
              ))}
            </ButtonGroup>
          </Card>
        </div>
        <div className="flex-grow">
          <Card>
            <Card.Heading>Loading Button</Card.Heading>
            <Button
              variant="primary"
              size="lg"
              disabled={loading}
              onClick={() => setLoading(true)}
              className="flex items-center justify-center gap-2"
            >
              {loading && <SpinnerIcon />}
              {loading ? 'Loading' : 'Load'}
            </Button>
          </Card>
        </div>
        <div className="flex-grow">
          <Card>
            <Card.Heading>Long Button in Constrained Area</Card.Heading>
            <div className="max-w-prose">
              <Button>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                tempora laborum architecto hic in fugiat quasi, autem cumque
                temporibus, minima suscipit quos? Facere, aliquid quo. Delectus,
                tempora doloribus. Illo, ullam.
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
}

function ActionVariants() {
  const variants = [
    'default',
    'ghost',
    'primary',
    'link',
    'destructive',
    'caution',
  ] as const;

  const [variant, setVariant] = useState<(typeof variants)[number]>('default');

  // TODO add link variants

  return (
    <Card>
      <Card.Heading>Action Variants</Card.Heading>
      <SimpleSelect
        label={<code>variant</code>}
        options={variants.map((v) => ({ label: <code>{v}</code>, value: v }))}
        value={variant}
        onChange={setVariant}
      />
      {[false, true].map((loading) => (
        <Fragment key={`loading-${loading}`}>
          {[false, true].map((pressed) => {
            const variationText = [loading && 'Loading', pressed && 'Pressed']
              .filter(isTruthy)
              .join(' ');
            return (
              <Fragment key={`pressed-${pressed}`}>
                <Card.Heading>
                  {variationText || 'Defaults for'} Variant
                </Card.Heading>
                <Action variant={variant} loading={loading} pressed={pressed}>
                  Text Only {variationText}
                </Action>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  {(['sm', 'default'] as const).map((size) => (
                    <Card key={`icon-size-${size}`}>
                      <Card.Heading>
                        Icon Size <code>{size}</code>
                      </Card.Heading>
                      <div className="flex items-end gap-4">
                        {/* wrapped for intrinsic size; otherwise it would fill the area */}
                        <Action
                          variant={variant}
                          accessibilityLabel={`Icon Only ${variationText}`}
                          icon={faSparkles}
                          iconSize={size}
                          loading={loading}
                          pressed={pressed}
                        />
                      </div>
                      <Action
                        variant={variant}
                        icon={faSparkles}
                        iconSize={size}
                        loading={loading}
                        pressed={pressed}
                      >
                        Icon on Left (default) {variationText}
                      </Action>
                      <Action
                        variant={variant}
                        icon={faSparkles}
                        iconSize={size}
                        side="right"
                        loading={loading}
                        pressed={pressed}
                      >
                        Icon on Right {variationText}
                      </Action>
                    </Card>
                  ))}
                </div>
              </Fragment>
            );
          })}
        </Fragment>
      ))}
    </Card>
  );
}

/**
 * A development-only page to display various components in different states to get a visual overview of the design system.
 * Often just the primitives.
 */
export default function Swatches() {
  return (
    <Page
      backAction
      title="Swatches"
      size="fullWidth"
      subtitle="An visual overview of components."
    >
      <div className="flex flex-col gap-8">
        <SwatchSet
          heading={<Card.Heading>Buttons</Card.Heading>}
          description={
            <Card.Description>
              Buttons can be customized with <code>size</code> and{' '}
              <code>variant</code>, and also respect <code>focus</code>,{' '}
              <code>hover</code>, and <code>disabled</code> states.
            </Card.Description>
          }
        >
          <ButtonVariants />
          <ButtonUseCases />
        </SwatchSet>
        <SwatchSet
          heading={<Card.Heading>Actions</Card.Heading>}
          description={
            <Card.Description>
              Actions are an abstraction on top of <code>Button</code> and
              links. They include behavior for handling accessibility, loading
              states, and consistent display of icons.
            </Card.Description>
          }
        >
          <ActionVariants />
          {/* // TODO action use cases */}
        </SwatchSet>
      </div>
    </Page>
  );
}
