import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva, VariantProps } from 'class-variance-authority';
import { ForwardRefExoticComponent, FunctionComponent, SVGProps } from 'react';
import { cn } from '~/utils/shadcn';

export type SupportedIcon =
  | ForwardRefExoticComponent<SVGProps<SVGSVGElement>>
  | FunctionComponent<SVGProps<SVGSVGElement>>
  | IconDefinition;

export const iconVariants = cva(undefined, {
  variants: {
    size: {
      sm: 'size-4',
      default: 'size-5',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

/** Supports [HeroIcon](https://heroicons.com/) SVGs, or icons from [FontAwesome](https://fontawesome.com/). */
export default function Icon({
  icon: IconOrDefinition,
  className,
  accessibilityLabel,
  ...variants
}: {
  icon: SupportedIcon;
  /**
   * A label to describe the icon for screen readers.
   * Not always required, if the icon comes from FontAwesome, but highly recommended.
   *
   * Becomes an `aria-label` attribute on the icon.
   */
  accessibilityLabel?: string;
  className?: string;
} & VariantProps<typeof iconVariants>) {
  /**
   * Using `$$typeof` might be unstable across React versions.
   * It exists primarily to distinguish between React elements and other objects to prevent XSS attack
   * This reference specifically looks towards it existing on exotic components, i.e. forwarded refs.
   */

  return (
      '$$typeof' in IconOrDefinition || typeof IconOrDefinition === 'function'
    ) ?
      <IconOrDefinition
        aria-label={accessibilityLabel}
        data-icon-type="svg"
        className={cn(iconVariants(variants), className)}
      />
    : <FontAwesomeIcon
        aria-label={accessibilityLabel}
        data-icon-type="fontawesome"
        icon={IconOrDefinition}
        className={cn(iconVariants(variants), className)}
      />;
}
