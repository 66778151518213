import { ComponentProps, forwardRef, SVGProps } from 'react';
import { cn } from '~/utils/shadcn';
import Icon from './Icon';

const Spinner = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
      ref={ref}
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  ),
);

/**
 * A circle spinner icon.
 * Uses the `currentColor` for the stroke and fill colors, but this can be adjusted through the `className` with any text color.
 * A named `Icon` wrapper around the SVG, so it leverages all of the same props/variants.
 */
export default function SpinnerIcon({
  className,
  ...props
}: Omit<ComponentProps<typeof Icon>, 'icon'>) {
  return (
    <Icon
      icon={Spinner}
      className={cn('h-5 w-5 text-current motion-safe:animate-spin', className)}
      // eslint-disable-next-line react/jsx-props-no-spreading -- primitive-like component, may consider promoting to a primitive
      {...props}
    />
  );
}
