import { faShopify } from '@fortawesome/free-brands-svg-icons';
import { faBox } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { isTruthy, Pegasus } from 'corso-types';
import { FormEventHandler, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import EmptyStateAction from '~/components/EmptyStateAction';
import SwitchInput from '~/components/field/SwitchInput';
import FloatingSave from '~/components/FloatingSave';
import Page from '~/components/Page';
import { Action } from '~/components/ui/Action';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
  useConfigureGsp,
} from '~/hooks/useConfigSettings';
import useIsCorsoAdmin from '~/hooks/useIsCorsoAdmin';
import useIsTest from '~/hooks/useIsTest';
import { useProtectionRates } from '~/hooks/useProtectionRates';
import {
  useHasCheckoutFunctionality,
  useShopifyPlan,
} from '~/hooks/useShopifyPlan';
import { useStoreId } from '~/hooks/useStoreId';
import { useStoreRules } from '~/hooks/useStoreRules';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  shipProtectSettingsFormSchema,
  ShipProtectSettingsFormValues,
} from '~/types';
import { ShippingPlusWidgetPreview } from './ShippingPlusWidgetPreview';
import ManageShippingRates from './ShippingRates/ManageShippingRates';
import ShippingRateForm from './ShippingRates/ShippingRateForm';
import { StoreRuleDisplay } from './storeRules/StoreRuleDisplay';

function ShippingRates() {
  const { data: protectionRates, refetch: refetchProtectionRates } =
    useProtectionRates();

  const [showForm, setShowForm] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { mutate: configureSp, isPending } = useConfigureGsp();

  const ratesExist = protectionRates && protectionRates.length > 0;

  return (
    <Card>
      <div className="flex flex-row items-center justify-between">
        <Card.Heading>Shipping Rates</Card.Heading>

        <div className="flex space-x-2">
          <Action
            icon={faShopify}
            loading={isPending}
            onClick={() => setShowConfirmationModal(true)}
            accessibilityLabel="Refresh Rates from Shopify"
            variant="ghost"
          />
          <Action
            icon={PlusIcon}
            onClick={() => setShowForm(true)}
            accessibilityLabel="Add Rate"
            variant="ghost"
          />
        </div>

        <ConfirmModal
          prompt="Are you sure you'd like to sync your Shipping Rates and Shipping Zone Rules?"
          title="Sync Rates And Shipping Zones"
          show={showConfirmationModal}
          confirmText="Confirm"
          variant="primary"
          onConfirm={() => {
            configureSp(true);
            setShowConfirmationModal(false);
            refetchProtectionRates().catch(console.error);
          }}
          onCancel={() => setShowConfirmationModal(false)}
        />
      </div>

      {!ratesExist ?
        <EmptyStateAction.Button
          onClick={() => setShowForm(true)}
          icon={<FontAwesomeIcon icon={faBox} />}
          label="Create A Shipping Rate"
        />
      : <ManageShippingRates rates={protectionRates} editable />}
      <ShippingRateForm show={showForm} onClose={() => setShowForm(false)} />
    </Card>
  );
}

function ShippingZoneRules() {
  const location = useLocation();
  const storeId = useStoreId();
  const { data: storeRules } = useStoreRules();

  const quoteShipmentAutomationRules =
    storeRules
      ?.filter((sr) => sr.rule.event.type === 'quoteOrderShipment')
      .sort(
        (a, b) =>
          new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf(),
      ) ?? [];

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Card.Heading>Shipping Zone Rules</Card.Heading>

        <Action
          icon={PlusIcon}
          variant="ghost"
          accessibilityLabel="Add New Shipping Zone Rule"
          to={{
            pathname: `/${storeId}/settings/automations/create`,
            search: new URLSearchParams({
              eventType: Pegasus.EventType.quoteOrderShipment,
              returnTo: location.pathname,
            }).toString(),
          }}
        />
      </div>

      {!quoteShipmentAutomationRules.length && (
        <Alert
          title="No Rules Configured"
          message="To offer Shipping Plus, you must first configure a Shipping Zone Rule."
          variant="DEFAULT"
        />
      )}

      <ul className="flex flex-col gap-2">
        {quoteShipmentAutomationRules.map((storeRule) => (
          <li key={storeRule.id}>
            <StoreRuleDisplay
              storeRule={storeRule}
              returnTo={location.pathname}
            />
          </li>
        ))}
      </ul>
    </Card>
  );
}

function ShippingRateModificationRules() {
  const location = useLocation();
  const storeId = useStoreId();
  const { data: storeRules } = useStoreRules();

  const hasCheckoutExtension = useHasCheckoutFunctionality();

  const quoteShipmentModifyRateRules =
    storeRules
      ?.filter((sr) => sr.rule.event.type === 'quoteOrderShipmentModify')
      .sort(
        (a, b) =>
          new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf(),
      ) ?? [];

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Card.Heading>Shipping Rate Modification Rules</Card.Heading>

        <Action
          icon={PlusIcon}
          variant="ghost"
          accessibilityLabel="Add New Shipping Rate Modification Rule"
          to={{
            pathname: `/${storeId}/settings/automations/create`,
            search: new URLSearchParams({
              eventType: Pegasus.EventType.quoteOrderShipmentModify,
              returnTo: location.pathname,
            }).toString(),
          }}
        />
      </div>

      {!hasCheckoutExtension && (
        <Alert
          title="Shopify Plan Limitation"
          message="Some modifications can only be utilized on Shopify Plus, contact us for more information."
          variant="warning"
        />
      )}
      <ul className="flex flex-col gap-2">
        {quoteShipmentModifyRateRules.map((storeRule) => (
          <li key={storeRule.id}>
            <StoreRuleDisplay
              storeRule={storeRule}
              returnTo={location.pathname}
            />
          </li>
        ))}
      </ul>
    </Card>
  );
}

function AppUpgradeRequired() {
  return (
    <Page title="Shipping Plus">
      <Card>
        <Alert
          title="App Upgrade Required"
          message="In order to use Shipping Plus, you must upgrade your Corso app to the latest version. This can be done by selecting the Corso app in your Shopify admin and clicking the 'Update' button."
          variant="DEFAULT"
        />
      </Card>
    </Page>
  );
}

export default function ShippingPlusSettings() {
  const formId = 'shipping-protection-settings';
  const formRef = useRef<HTMLFormElement>(null);

  const {
    storeUser: {
      store: { id: storeId },
    },
  } = useMerchantContext();

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();

  const methods = useForm<ShipProtectSettingsFormValues>({
    resolver: zodResolver(shipProtectSettingsFormSchema),
    values: data,
  });

  const isCorsoAdmin = useIsCorsoAdmin();
  const isTest = useIsTest();

  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit((values) => saveChanges({ shippingProtection: values }))(
        event,
      )
      .catch(console.error);
  };

  const {
    isShippingLineProtectionOffered,
    hasWriteShippingScope,
    hasDeliveryCustomizationScope,
  } = data ?? {};

  const isEligible = hasWriteShippingScope && hasDeliveryCustomizationScope;

  const shopifyPlan = useShopifyPlan();

  const displayPlanAlert = shopifyPlan === 'Basic' || shopifyPlan === 'Shopify';

  const isAdminOrTest = isCorsoAdmin || isTest;

  const showCheckoutSection = useHasCheckoutFunctionality();

  if (!isEligible) return <AppUpgradeRequired />;

  return (
    <Page
      title="Shipping Plus"
      secondaryActions={[
        isShippingLineProtectionOffered && {
          id: 'disable-shipping-plus',
          content: 'Turn Off',
          onAction: () => {
            methods.setValue('isShippingLineProtectionOffered', false, {
              shouldDirty: true,
            });
            formRef.current?.requestSubmit();
          },
        },
      ].filter(isTruthy)}
      primaryAction={
        isShippingLineProtectionOffered ? undefined : (
          {
            onAction: () => {
              methods.setValue('isShippingLineProtectionOffered', true, {
                shouldDirty: true,
              });
              formRef.current?.requestSubmit();
            },
            content: 'Turn On',
          }
        )
      }
    >
      <form
        ref={formRef}
        className="flex flex-col gap-4"
        id={formId}
        onSubmit={submitHandler}
        onReset={(e) => {
          e.preventDefault();
          methods.reset();
        }}
      >
        <div className="flex flex-col gap-4">
          <FloatingSave
            isDirty={methods.formState.isDirty}
            isSubmitting={methods.formState.isSubmitting}
            form={formId}
          />

          {!isShippingLineProtectionOffered && (
            <Alert
              variant="info"
              title="Shipping Plus is Disabled"
              message="Settings can be configured, but Plus Rates will not be offered to customers."
            />
          )}

          {displayPlanAlert && (
            <Card>
              <Alert
                variant="DEFAULT"
                title="Shopify & Shopify Basic"
                message="Your Shopify plan may not support the features required to use Shipping Plus. Please contact us for more information."
              />
            </Card>
          )}

          <ShippingZoneRules />
          {isAdminOrTest && <ShippingRateModificationRules />}
          <ShippingRates />
        </div>

        {showCheckoutSection && (
          <Card>
            <Card.Heading>Checkout </Card.Heading>

            {/* the follow settings are only available to Shopify Plus merchants or merchants with extensibility */}

            <Controller
              control={methods.control}
              name="widgetConfig.shippingPlus.shouldUseDiscountedPrice"
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id="use-discounted-price"
                  label="Use Discounted Price for Rate Conditions"
                  details="When enabled, Plus rate conditions will be calculated based on the discounted price of the cart. "
                  onChange={onChange}
                  checked={!!value}
                  error={fieldState.error?.message}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="widgetConfig.shippingPlus.isFreeShippingOptInEnabled"
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id="free-shipping-checkbox"
                  label="Free Shipping Terms of Service"
                  details="When enabled, customers can accept free shipping terms of service to be offered a free shipping rate."
                  onChange={onChange}
                  checked={!!value}
                  error={fieldState.error?.message}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="isWidgetProtectionOffered"
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id="offer-shipping-protection"
                  label="Show Checkout Widget"
                  details="When enabled, the checkout widget can be used to display additional information about the Shipping Plus experience."
                  checked={!!value}
                  onChange={onChange}
                  error={fieldState.error?.message}
                />
              )}
            />

            <div className="flex items-center gap-2">
              <Card.Heading>Widget Preview </Card.Heading>
              <Action
                icon={PencilIcon}
                variant="ghost"
                accessibilityLabel="Edit Widget"
                to={`/${storeId}/settings/shipping-plus/widget`}
              />
            </div>
            <ShippingPlusWidgetPreview config={methods.watch('widgetConfig')} />
          </Card>
        )}

        {isAdminOrTest && (
          <Card>
            <Card.Heading>Delivery Customization</Card.Heading>
            <Controller
              control={methods.control}
              name="widgetConfig.shippingPlus.isReorderShippingLinesEnabled"
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id="reorder-shipping-lines"
                  label="Reorder Shipping Options"
                  details="When enabled, the Plus rates will be reordered to appear at the top of the Shipping Options."
                  onChange={onChange}
                  checked={!!value}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Controller
              control={methods.control}
              name="widgetConfig.shippingPlus.isHideAllShopifyLinesEnabled"
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id="hide-shopify-lines"
                  label="Hide All Non-Corso Shipping Options"
                  details="When enabled, all shipping options provided by Shopify or other third-party apps will be hidden when Corso rates are available."
                  onChange={onChange}
                  checked={!!value}
                  error={fieldState.error?.message}
                />
              )}
            />

            <Controller
              control={methods.control}
              name="widgetConfig.shippingPlus.isDistributeSplitShippingEnabled"
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id="distribute-split-shipping"
                  label="Distribute Shipping Cost Across Split Shipments"
                  details="When enabled, the shipping cost will be distributed across shipments, rather than being applied to each shipment. (This feature requires Split Shipments to be enabled in the Shopify admin)"
                  onChange={onChange}
                  checked={!!value}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Card>
        )}
      </form>
    </Page>
  );
}
