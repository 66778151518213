import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown19,
  faArrowDownAZ,
  faArrowUp91,
  faArrowUpZA,
  faCalendarDays,
  faList,
  faMoneyBillWave,
  faQuestionCircle,
  faTags,
  faTruckFast,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { Pegasus } from 'corso-types';
import { groupBy } from 'corso-utils/map';
import { JSX, useMemo, useState } from 'react';
import ButtonGroup from '~/components/ButtonGroup';
import GridList from '~/components/GridList';
import Page from '~/components/Page';
import { Action } from '~/components/ui/Action';
import { Badge } from '~/components/ui/primitives/Badge';
import useIsTest from '~/hooks/useIsTest';
import { useStoreRules } from '~/hooks/useStoreRules';
import { displayName, eventTypeDescription } from './StoreRuleForm';

// ? maybe merge with `displayName`, and rename `displayName` to `displayContent` or something else
export const eventTypeIcon: Partial<Record<Pegasus.EventType, JSX.Element>> = {
  [Pegasus.EventType.modifyResolutionWindow]: (
    <FontAwesomeIcon icon={faCalendarDays} />
  ),
  [Pegasus.EventType.askAPolicyEnforcingQuestion]: (
    <FontAwesomeIcon icon={faQuestionCircle} />
  ),
  [Pegasus.EventType.collectCustomFields]: <FontAwesomeIcon icon={faList} />,
  [Pegasus.EventType.applyClaimTags]: <FontAwesomeIcon icon={faTags} />,
  [Pegasus.EventType.applyOrderTags]: <FontAwesomeIcon icon={faTags} />,
  [Pegasus.EventType.applyFee]: <FontAwesomeIcon icon={faMoneyBillWave} />,
  [Pegasus.EventType.chargeForExchangeOrderShipping]: (
    <FontAwesomeIcon icon={faMoneyBillWave} />
  ),
  [Pegasus.EventType.chargeForReturnLabel]: (
    <FontAwesomeIcon icon={faMoneyBillWave} />
  ),
  [Pegasus.EventType.offerInstantExchange]: (
    <FontAwesomeIcon icon={faTruckFast} />
  ),
};

/** Returns an icon for the event type if an override has been specified; otherwise, provides the configured fallback icon instead. */
export const getEventTypeIcon = (eventType?: Pegasus.EventType | null) =>
  eventType ?
    (eventTypeIcon[eventType] ?? <SparklesIcon aria-hidden="true" />)
  : <SparklesIcon aria-hidden="true" />;

type EventTypeCategory = {
  eventType: Pegasus.EventType;
  label: string;
  description: string;
  icon: JSX.Element;
  enabledCount: number;
  ruleCount: number;
};

type SortDirection = 'asc' | 'desc';

const sortDirectionLabel = {
  asc: 'Ascending',
  desc: 'Descending',
} as const satisfies Record<SortDirection, string>;

const sorter = {
  alphabetical: {
    label: 'Alphabetical',
    asc: {
      icon: faArrowDownAZ,
      sort: (a, b) => a.label.localeCompare(b.label),
    },
    desc: { icon: faArrowUpZA, sort: (a, b) => b.label.localeCompare(a.label) },
  },
  count: {
    label: 'Count',
    asc: {
      icon: faArrowDown19,
      sort: (a, b) => {
        const enabledCount = a.enabledCount - b.enabledCount;
        const ruleCount = a.ruleCount - b.ruleCount;
        return enabledCount || ruleCount;
      },
    },
    desc: {
      icon: faArrowUp91,
      sort: (a, b) => {
        const enabledCount = b.enabledCount - a.enabledCount;
        const ruleCount = b.ruleCount - a.ruleCount;
        return enabledCount || ruleCount;
      },
    },
  },
} as const satisfies Record<
  string,
  { label: string } & {
    [K in SortDirection]: {
      icon: IconDefinition;
      sort: (a: EventTypeCategory, b: EventTypeCategory) => number;
    };
  }
>;

export default function StoreRulesOverview() {
  const { data: storeRules = [] } = useStoreRules();
  const isTest = useIsTest();

  const [sortByKind, setSortByKind] =
    useState<keyof typeof sorter>('alphabetical');

  const [sortDirectionCount, setSortDirectionCount] =
    useState<SortDirection>('desc');
  const [sortDirectionAlphabetical, setSortDirectionAlphabetical] =
    useState<SortDirection>('asc');

  const categories = useMemo(() => {
    const storeRulesByEventType = groupBy(
      storeRules,
      (storeRule) => storeRule.rule.event.type,
    );

    const sortDirection =
      sortByKind === 'alphabetical' ?
        sortDirectionAlphabetical
      : sortDirectionCount;

    return Object.values(Pegasus.EventType)
      .map((eventType) => {
        const eventTypeStoreRules = storeRulesByEventType.get(eventType) ?? [];

        return {
          eventType,
          label: displayName.eventType[eventType],
          description: eventTypeDescription[eventType],
          icon: getEventTypeIcon(eventType),
          enabledCount: eventTypeStoreRules.filter(
            (storeRule) => storeRule.isEnabled,
          ).length,
          ruleCount: eventTypeStoreRules.length,
        } satisfies EventTypeCategory;
      })
      .filter(
        (rule) =>
          isTest ||
          !Pegasus.eventsAvailableForTestOnly.includes(rule.eventType),
      )
      .sort(sorter.alphabetical.asc.sort) // baseline alphabetical ascending sort
      .sort(sorter[sortByKind][sortDirection].sort);
  }, [
    sortByKind,
    sortDirectionAlphabetical,
    sortDirectionCount,
    storeRules,
    isTest,
  ]);

  return (
    <Page title="Automations">
      <div className="space-y-4 md:space-y-6">
        <section>
          <GridList.Wrapper>
            <GridList.ItemLink
              to="templates"
              label="Start from a Template"
              description="Explore some common automations to get started quickly, or use them for inspiration."
            />
          </GridList.Wrapper>
        </section>
        <section className="space-y-2">
          <div className="flex items-center justify-between">
            <h2 className="px-3 text-sm font-bold uppercase text-corso-gray-700 md:px-0">
              Automations by Action
            </h2>
            <div className="flex items-center gap-2">
              <p className="text-xs font-semibold">Sort</p>
              <ButtonGroup>
                <Action
                  accessibilityLabel={`${sorter.alphabetical.label} ${sortDirectionLabel[sortDirectionAlphabetical]}`}
                  icon={sorter.alphabetical[sortDirectionAlphabetical].icon}
                  onClick={() => {
                    if (sortByKind !== 'alphabetical') {
                      setSortByKind('alphabetical');
                    } else {
                      setSortDirectionAlphabetical((current) =>
                        current === 'asc' ? 'desc' : 'asc',
                      );
                    }
                  }}
                  pressed={sortByKind === 'alphabetical'}
                />
                <Action
                  accessibilityLabel={`${sorter.count.label} ${sortDirectionLabel[sortDirectionCount]}`}
                  icon={sorter.count[sortDirectionCount].icon}
                  onClick={() => {
                    if (sortByKind !== 'count') {
                      setSortByKind('count');
                    } else {
                      setSortDirectionCount((current) =>
                        current === 'asc' ? 'desc' : 'asc',
                      );
                    }
                  }}
                  pressed={sortByKind === 'count'}
                />
              </ButtonGroup>
            </div>
          </div>
          <GridList.Wrapper>
            <GridList columns={3} data={categories}>
              {({
                eventType,
                label,
                description,
                icon,
                enabledCount,
                ruleCount,
              }) => (
                <GridList.ItemLink
                  key={eventType}
                  to={{
                    pathname: 'list',
                    search: `?eventType=${eventType}`,
                  }}
                  label={label}
                  description={description}
                  icon={icon}
                  variant={
                    // eslint-disable-next-line no-nested-ternary
                    enabledCount ?
                      'secondary' // using this event type; active
                    : ruleCount ?
                      'primary' // rules defined, but none are enabled; dormant
                    : 'DEFAULT' // no rules defined; inactive
                  }
                >
                  {!!ruleCount && (
                    <Badge>
                      {enabledCount} of {ruleCount} Enabled
                    </Badge>
                  )}
                </GridList.ItemLink>
              )}
            </GridList>
          </GridList.Wrapper>
        </section>
        <section>
          <GridList.Wrapper>
            <GridList.ItemLink
              to="list"
              label="All Automations"
              description="View and manage all the rules for your store."
            />
          </GridList.Wrapper>
        </section>
      </div>
    </Page>
  );
}
