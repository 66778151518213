import { PencilIcon } from '@heroicons/react/24/outline';
import DescriptionList from '~/components/DescriptionList';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import { SwitchInput } from '~/components/field';
import RelativeDateTime from '~/components/RelativeDateTime';
import { Action } from '~/components/ui/Action';
import { useStoreId } from '~/hooks/useStoreId';
import { useToggleStoreRule } from '~/hooks/useStoreRules';
import { StoreRule } from '~/types';
import StoreRuleDelete from './StoreRuleDelete';
import StoreRuleDuplicate from './StoreRuleDuplicate';
import {
  displayName,
  eventDisplay,
  StoreRuleExpressionDisplay,
} from './StoreRuleForm';

export function StoreRuleDisplay({
  storeRule,
  defaultOpen,
  returnTo,
}: {
  storeRule: StoreRule;
  defaultOpen?: boolean;
  returnTo: string;
}) {
  const storeId = useStoreId();
  const toggleRule = useToggleStoreRule();

  return (
    <Disclosure
      defaultOpen={defaultOpen}
      renderSummary={
        <SimpleSummary>
          <div className="flex w-full items-center justify-between pl-1">
            <div className="flex flex-col">
              <span>{storeRule.name}</span>
              <span className="text-xs text-corso-gray-500">
                Created <RelativeDateTime dateTime={storeRule.createdOn} />
              </span>
            </div>
            <div className="flex items-center gap-2">
              <SwitchInput
                id="isRuleEnabled"
                label="Enabled"
                labelVisuallyHidden
                checked={storeRule.isEnabled}
                onChange={() => toggleRule(storeRule)}
              />
              <Action
                accessibilityLabel={`Edit ${storeRule.name} Rule`}
                icon={PencilIcon}
                to={{
                  pathname: `/${storeId}/settings/automations/edit/${storeRule.id}`,
                  search: new URLSearchParams({ returnTo }).toString(),
                }}
              />
              <StoreRuleDuplicate storeRule={storeRule} />
              <StoreRuleDelete storeRule={storeRule} />
            </div>
          </div>
        </SimpleSummary>
      }
    >
      <div className="pl-7">
        <DescriptionList
          layoutType="list"
          descriptions={[
            { term: 'At', details: displayName.hook[storeRule.hook] },
            {
              term: 'Conditions',
              details: <StoreRuleExpressionDisplay rule={storeRule.rule} />,
            },
            {
              term: 'Action',
              details: (
                <div className="mb-2">
                  <p className="mb-1">
                    {displayName.eventType[storeRule.rule.event.type]}
                  </p>
                  {eventDisplay[storeRule.rule.event.type](
                    // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
                    {
                      ...storeRule.rule.event.params,
                      storeRuleId: storeRule.id,
                    } as never,
                  )}
                </div>
              ),
            },
          ]}
        />
      </div>
    </Disclosure>
  );
}
