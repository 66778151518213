import { CrewIncentiveTypeEnum } from 'corso-types';
import { ReactNode, useCallback } from 'react';
import ButtonGroup from '~/components/ButtonGroup';
import { Action } from '~/components/ui/Action';
import { InputTypeProps } from './Input';
import NumberInput from './NumberInput';

// focused subset of incentives
export const MonetaryType = {
  fixed: CrewIncentiveTypeEnum.fixed,
  percent: CrewIncentiveTypeEnum.percent,
} as const satisfies Record<string, CrewIncentiveTypeEnum>;

export type MonetaryType = (typeof MonetaryType)[keyof typeof MonetaryType];
export type MonetaryValue = { amount: number; type: MonetaryType };

function MonetaryTypeSwitch({
  type,
  setType,
}: {
  type: MonetaryType;
  setType: (type: MonetaryType) => void;
}) {
  return (
    <ButtonGroup>
      <Action
        pressed={type === MonetaryType.percent}
        onClick={() => setType(MonetaryType.percent)}
      >
        Percentage
      </Action>
      <Action
        pressed={type === MonetaryType.fixed}
        onClick={() => setType(MonetaryType.fixed)}
      >
        Fixed Amount
      </Action>
    </ButtonGroup>
  );
}

type MonetaryAmountInputProps = Omit<
  InputTypeProps<never>,
  'value' | 'onChange'
> & {
  id: string;
  label: string;
  details?: string;
  error?: ReactNode;
  currencyCode: string;
  currencySymbol: string;
  value: MonetaryValue;
  onChange: (value: MonetaryValue) => void;
};

/**
 * Rounds a monetary value to the specified precision.
 */
function roundMonetaryPercent(value: number) {
  const precision = 2; // only two digits, because the fractional part is limited by our database
  const factor = 10 ** precision;
  return Math.round(value * factor) / factor;
}

/** Enforced as a controlled input, since it modifies an object instead of a single value. */
// TODO review floating point precision issues; i.e. using arrow keys to increment from 6 to 7
export default function MonetaryRateInput({
  id,
  label,
  details,
  error,
  value,
  onChange,
  currencyCode,
  currencySymbol,
  ...props
}: MonetaryAmountInputProps) {
  const setType = useCallback(
    // resets `amount` to `0` when switching between types; could try to intelligently convert between types, but this works well enough
    (type: MonetaryType) => onChange({ type, amount: 0 }),
    [onChange],
  );

  const setAmount = useCallback(
    (amount: number) => {
      if (value.type === MonetaryType.fixed) {
        onChange({ type: value.type, amount });
      } else {
        // convert whole value entered to percentage
        onChange({
          type: value.type,
          amount: roundMonetaryPercent(amount / 100),
        });
      }
    },
    [onChange, value.type],
  );

  return (
    <NumberInput
      id={id}
      label={label}
      details={details}
      placeholder={value.type === MonetaryType.fixed ? '0.00' : '0'}
      step={value.type === MonetaryType.fixed ? '0.01' : '1'} // ! percent step is 1, because values can only have a precision of 2 fractional digits
      min={0}
      max={value.type === MonetaryType.fixed ? undefined : '100'}
      addon={{
        outsideStart: (
          <MonetaryTypeSwitch type={value.type} setType={setType} />
        ),
        // symbol placement/usage depends on locale, so this isn't perfect
        ...(value.type === MonetaryType.percent ?
          { insideEnd: '%' }
        : { insideStart: currencySymbol, insideEnd: currencyCode }),
      }}
      required
      value={
        // convert percentage to whole number for display and easier user input
        value.type === MonetaryType.fixed ?
          value.amount
        : roundMonetaryPercent(value.amount * 100)
      }
      onChange={(event) => setAmount(event.target.valueAsNumber)}
      error={error}
      {...props}
    />
  );
}
