import { useMutation, useQuery } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import api from '~/api';
import { queryClient } from '~/providers/QueryProvider';
import { useStoreId } from './useStoreId';

// TODO: add some tests on this ??
const queryKeyPrefix = 'protectionRates';
export function useProtectionRates() {
  const storeId = useStoreId();

  return useQuery({
    queryKey: [queryKeyPrefix, storeId],
    queryFn: () => api.store(storeId).shippingProtection.protectionRates(),
  });
}

export function useProtectionRateUpsert() {
  const storeId = useStoreId();

  const { refetch } = useProtectionRates();

  return useMutation({
    mutationFn: (
      protectionRate:
        | CrewMerchantUi.ProtectionRateCreate
        | CrewMerchantUi.ProtectionRateUpdate,
    ) => {
      if ('id' in protectionRate && typeof protectionRate.id === 'number') {
        return api
          .store(storeId)
          .shippingProtection.updateProtectionRate(
            `${protectionRate.id}`,
            protectionRate,
          );
      }
      return api
        .store(storeId)
        .shippingProtection.createProtectionRate(protectionRate);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeyPrefix, storeId] }),
    onSettled: () => refetch(),
  });
}

export function useProtectionRateDelete() {
  const storeId = useStoreId();
  const { refetch } = useProtectionRates();

  return useMutation({
    mutationFn: api.store(storeId).shippingProtection.deleteProtectionRate,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeyPrefix, storeId] }),
    onSettled: () => refetch(),
  });
}
